.heading-on-top {
  a {
    color: #888 !important;
    font-weight: 600;
  }

  li {
    padding-left: 30px !important;
    cursor: pointer;
  }
}

.field-own {
  width: 500px;
  background: #fff;
  padding: 50px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  box-shadow: 0 0 4px #848484;

  label {
    color: #888 !important;
    padding-left: 5px;
  }

  .form-control {
    height: 36px;
    border-radius: 2px;
    font-weight: 600;
  }
}

.map-bg {
  background-color: #f5f5f8 !important;
}

.footer-btn-group {
  position: absolute !important;
  bottom: 0;
}

.add-a-step-margin {
  margin-right: 70px;
  margin-top: 15px;
  margin-bottom: 15px !important;
}

.map-border-bottom {
  border-bottom: 2px solid #e0e0e0;
}