@import 'src/frontend-common-libs/src/scss/definitions';
@import 'src/frontend-common-libs/src/scss/input';

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.run-plate-id {
    width: 187px;
  }

  .label-and-error {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  input[type='text'],
  textarea {
    @extend %input-textarea;
  }

  textarea {
    overflow: auto;
    resize: none;
    white-space: pre-wrap;
    height: 80px;
    padding: 9px 8px;
    font-size: 11px;
  }

  &.error {
    input,
    textarea {
      border-color: #ff3d55;
      color: $newDarkGrey;

      &:focus {
        outline: none;
      }
    }
  }

  input#run-name {
    font-size: 14px;
  }
}

.control-label {
  font-size: 13px;
  font-weight: 500;
  color: $newDarkGrey !important;

  &.large {
    font-size: 16px;
    font-weight: 400;
  }
}

.error-message {
  font-size: 12px;
  font-weight: 400;
  color: #ff3d55;
}

.status-direction {
  display: flex;
  flex-direction: column;
}