#maintenance-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;

  img {
    width: 150px;
    margin-bottom: 40px;
  }

  h1 {
    font-weight: 400;
    max-width: 450px;
    text-align: center;
    font-size: 27px;
    margin: 0;
  }
}
