@import '../../../../frontend-common-libs/src/scss/definitions';

:global(.base-table).fleetManagementTable {
  @mixin model-col {
    padding-left: 8px;
    min-width: 200px;
    /*  hide model  */
    @media only screen and (max-width: $tabletPortraitMaxWidth) {
      display: none;
    }
  }

  @mixin name-col {
    min-width: 150px;
  }

  td:first-of-type, th:first-of-type {
    margin: 0;
  }

  th,
  td:nth-of-type(2) {
    @include model-col;
  }

  th,
  td:nth-of-type(3) {
    @include name-col;
  }

  td {
    margin-top: 2px;
  }

  td:nth-of-type(1),
  td:nth-of-type(2) {
    margin-top: 0;
  }

  thead {
    margin: 0;
    border-bottom: none;
    font-size: 14px;
    letter-spacing: 0.5px;

    tr {
      height: 38px;
    }

    td {
      font-weight: 500;
    }
  }

  tr {
    &:focus {
      outline: none;
      background-color: $tableRowBackgroundColor;
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus,
    &:focus-within {
      td:first-of-type {
        background-color: $white;
      }
    }

    td:first-of-type {
      height: 34px;
      padding-top: 10px
    }

    margin: 0;
    height: 34px;
  }

  font-weight: 500;
  margin: 0;
}

:global(.base-table.hidden).fleetManagementTable {
  display: none;
}