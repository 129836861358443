@import '../../../../scss/definitions';
@import '../../../common/dialogs/styles/modal-dialog-v2';

.version-conflict {
  @extend %modal-dialog-style-v2;
  @include modal-dialog-width(433px);

  .modal-header-own {
    border-bottom: none;
    height: 70px;

    h4 {
      color: #009136;
      margin-left: 70px;
      margin-top: -20px;

      &:before {
        background-image: url(../../../../../../../img/info.svg);
        background-size: 45px;
        display: inline-block;
        width: 45px;
        height: 45px;
        content: "";
        position: absolute;
        left: 24px;
        top: 15px
      }
    }

    .close {
      margin-top: -18px;
    }
  }

  .modal-body {
    padding: 0 24px 0 24px;
  }

  .modal-footer {
    border-top: none;
    padding: 0;

    .modal-footer-own {
      display: flex;
      justify-content: center;
      padding: 0;
      margin: 35px 24px 24px 24px;

      #modal-ok {
        min-width: 80px;
      }

      #modal-cancel {
        display: none;
      }
    }
  }
}

.version-conflict-panel {
  font-size: 14px;
  color: $newDarkGrey;
  display: flex;
  flex-direction: column;

  .bold-text {
    font-weight: 500;
    margin-top: 12px;
  }

  .normal-text {
    margin-left: 24px;
    font-weight: normal;
    font-size: 14px;
    color: $newDarkGrey;
  }

  label {
    display: flex;
    font-weight: 500;
    margin-top: 12px;

    &:focus {
      outline: none;
    }
  }

  .new-file-label {
    margin-top: 8px;
  }

  .save-as-file-name {
    margin: 4px 24px 0 24px;
    border-radius: 3px;
    height: 30px;
    padding-left: 8px;
  }

  .file-name-error {
    font-size: 12px;
    font-weight: 400;
    color: $invalidColor;
    margin: 4px 28px 0 28px;
    height: 20px;
  }
}