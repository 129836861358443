.base-table#archive-table {
  tbody {
    tr {
      &:hover {
        cursor: default;
      }

      &:focus {
        outline: none;
        background-color: $tableRowBackgroundColor;

        .name-cell {
          text-decoration: none;
        }
      }

      .name-cell {
        color: $newDarkGrey;
      }
    }
  }
}
