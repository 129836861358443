/* CSS for the Drop Zone component */

.drop-zone-own-wrapper {
  position: relative;
}

.drop-zone-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 2;
}

.drop-zone-overlay-bg {
  background: rgba(70, 130, 180, 0.5);
}

.drop-zone-valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.drop-zone-hint {
  color: slategray;
  font-weight: lighter;
  font-size: 12px;
}

.drop-zone-empty {
  color: gray;
}

.drop-zone-choose-btn {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}
