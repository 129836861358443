@import '../../../src/frontend-common-libs/src/scss/definitions';

.role-image {
  width: 24px;
  height: 24px;
}

.loading-spinner {
  color: $headerGreyColor;
  width: 24px;
}