@import '../../frontend-common-libs/src/scss/definitions';

.pcr-data-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 1px;
  height: 100%;
  min-width: 430px;
  overflow-x: auto;
  #pcr-toolbar {
    display: flex;
    box-shadow: $mediumShadow;
    background-color: $toolbarBackgroundColor;
    font-family: $rubikFontFamily;
    align-items: center;
    padding: 0 23px 0 17px;
    height: 40px;
    z-index: 2;

    .button-group {
      margin-top: 0;
    }

    #download-csv,
    .baseline-toggle-wrapper {
      margin-right: 20px;
      .baseline-label {
        font-weight: normal;
        font-size: 12px;
        color: $lightblue;
        margin-right: 4px;
      }
      .radio-button-toggle {
        label {
          font-weight: normal;
          font-size: 12px;
          color: $lightblue;
          border: solid 1px $lightblue;
          &.btn {
            padding-top: 0px;
            padding-bottom: 0px;
          }
          &.btn-default {
            &.active,
            &:hover {
              background-color: $toolbarBackgroundColor;
            }
          }
        }
        &:focus-within,
        &:focus {
          box-shadow: $insetShadow, $glowShadow;
          outline: none;
          label {
            border: solid 1px $focusedElementBorderColor;
          }
        }
      }
    }

    .step-dropdown {
      .dropdown {
        width: 60px;
      }
    }

    .dropdown-label {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      font-weight: normal;
      margin: 0;
      color: $lightblue;
      font-size: 12px;
      &[disabled] {
        color: $disabledColor;
        cursor: not-allowed;
      }
      .dropdown {
        margin-left: 4px;
      }
    }

    .dropdown-toggle {
      font-family: $rubikFontFamily;
      color: $newDarkGrey;
      font-size: 12px;
      background-color: $toolbarBackgroundColor;
      border: solid 1px $lightblue;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.pcr-step-dropdown {
        min-height: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
        min-width: auto;
        width: 100%;
        font-weight: normal;
        font-size: 12px;
        color: $lightblue;
        &[disabled] {
          border: solid 1px $disabledColor;
          color: $disabledColor;
          cursor: not-allowed;
        }
      }
      &:hover {
        cursor: pointer;
      }
      &:focus {
        border: solid 1px $focusedElementBorderColor;
        box-shadow: $insetShadow, $glowShadow;
        outline: none;
        background-color: $toolbarBackgroundColor;
      }
      &:focus-within:focus {
        background-color: $toolbarBackgroundColor;
      }
    }
  }

  #filter,
  #analysis-settings {
    position: relative;
    margin-right: 20px;
    .new-btn-icon[aria-expanded='true'] {
      color: $secondaryDarkBlue;
      outline: none;
      text-decoration: none;
      .icon {
        @include hoverColorFilter;
      }
      &:focus {
        outline: 1px solid $focusedElementBorderColor;
      }
    }
    .new-btn-icon[aria-disabled='true'] {
      background-color: inherit;
      font-size: 12px;
      color: $disabledColor;
      cursor: not-allowed;
      & .icon {
        @include disabledColorFilter;
      }
    }
  }
  .pcr-data-wrapper {
    overflow: auto;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    .pcr-data {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;

      &.analysis {
        min-width: 1314px;
      }

      .pcr-workflow-view {
        background-color: $grayBackground;
        flex: 1 0 1px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        .pcr-no-data-message {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1 0 1px;
          font-size: 1.2em;
        }
      }
      .pcr-data-chart-options {
        height: 49px;
        background-color: #ffffff;
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
        padding: 0px 25px;
        display: flex;
        justify-content: flex-end;
        z-index: 1;

        .actions {
          display: flex;
          flex-grow: 1;
          align-items: center;
          padding-top: 3px;

          #quick-save-results-button img {
            width: 20px;
            height: 20px;
          }
        }

        .dropdown-menu {
          font-size: 12px;
          > li {
            a[role='menuitem'] {
              height: 30px;
              padding: 7px 15px;
            }
          }
        }
        .step-dropdown {
          .dropdown {
            width: 60px;
          }
          .dropdown-menu {
            width: 100px;
            min-width: auto;
          }
        }

        .dropdown-label {
          display: flex;
          flex: 0 0 auto;
          align-items: center;
          font-weight: normal;
          margin: 0 5px;
          .dropdown {
            margin-left: 12px;
          }
        }
        .dropdown-toggle {
          color: #161616;
          font-size: 12px;
          background-color: #fff;
          border: solid 1px #dfdfdf;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.pcr-step-dropdown {
            min-width: auto;
            width: 100%;
          }
        }
        .pcr-step-value {
          a {
            margin: 0;
            max-width: none;
            padding: 8px 12px;
            font-size: 12px;
          }
        }
      }
      .pcr-results {
        flex: 1 0 1px;
        display: flex;
        overflow: auto;
        z-index: 0;
        .table-title {
          font-size: 14px;
          color: $newDarkGrey;
          font-weight: 500;
          margin: 12px 0 8px 1px;
          position: sticky;
          top: 0;
          background-color: $white;
          margin-top: 0;
        }
        .pcr-chart-container {
          position: relative;
          .pcr-chart-controls {
            position: absolute;
            bottom: 0px;
            margin-left: 8px;
            display: flex;

            #amp-chart-scale-option {
              margin-left: 33px;
            }

            #melt-chart-mode-option {
              margin-left: 33px;
            }
          }
        }
        .pcr-results-wrapper {
          flex: 1 0 1px;
          display: flex;
          width: 100%;
          min-height: 545px; // maintain well selector multi-select functionality up to window height of 768px
          margin: 8px;
          overflow: hidden;
          .pcr-data-and-chart {
            width: 816px;
            display: flex;
            flex-direction: column;
            margin-right: 2px;
            .pcr-chart {
              padding: 2px 11px 9px 4px;
              margin-bottom: 2px;
              border: solid 1px $frameBorderGrayColor;
              background-color: $white;
            }

            .pcr-plate-grid-wrapper {
              overflow: auto;
              flex-grow: 1;
              background-color: $white;
              border: solid 1px $frameBorderGrayColor;
              padding-right: 13px;

              .pcr-plate-grid {
                overflow: auto;
                flex: 1 0 1px;
                position: relative;
                transform: translateZ(0);
                height: 100%;

                padding: 7px 0 12px 9px;

                .header-table-well {
                  .analysis-select-all {
                    position: absolute;
                    background-color: $plateHeaderBackgroundColor;
                    color: $lightblue;
                    font-size: 11px;
                    font-family: $rubikFontFamily;
                    margin-left: -28px;
                  }
                }

                .table-well {
                  table-layout: fixed;
                  padding: 24px 0px 0px 28px;

                  .col-headers-row {
                    position: absolute;
                    z-index: 10;
                    margin-top: -24px;
                  }

                  .row-well-table {
                    .row-header {
                      position: absolute;
                      z-index: 9;
                      margin-left: -28px;
                    }
                  }

                  .table-headers {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 11px;

                    font-family: $rubikFontFamily;

                    background-color: $plateHeaderBackgroundColor;
                    color: $lightblue;

                    &:hover,
                    &:focus {
                      background-color: $darkGrey;
                      color: $white;
                    }

                    &:active {
                      background-color: $wellDefaultHoverColor;
                      color: $white;
                    }
                  }

                  .header-table-well,
                  .row-well-table {
                    display: flex;

                    .table-headers.cell-well {
                      outline: none;
                      &:focus {
                        box-shadow: $glowShadow;
                        border: solid 1px $focusedElementBorderColor;
                      }
                    }

                    .cell-well {
                      flex: 1;
                      min-width: 60px;
                      height: 20px;
                      margin-right: 4px;
                      margin-bottom: 4px;
                      padding: 0;
                      border: none;

                      &:last-child {
                        margin-right: 0px;
                        margin-bottom: 0px;
                      }

                      &:first-child {
                        cursor: pointer;
                        flex: none;
                        min-width: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                    }
                  }

                  .well-unit {
                    display: flex;
                    font-size: 11px;
                    .well-button-wrapper {
                      width: 100%;
                      pointer-events: auto;

                      .well-button {
                        width: 100%;
                        height: 20px;

                        font-size: 11px;
                        font-family: $rubikFontFamily;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;

                        border: none;

                        background-color: $wellDefaultBackgroundColor;
                        color: $fontWellColor;

                        &:focus {
                          color: $newDarkGrey;
                          box-shadow: $glowShadow;
                          border: solid 1px $focusedElementBorderColor;
                          outline: none;
                          font-family: $rubikFontFamily;
                        }

                        &:hover {
                          background-color: $wellActiveBackgroundColor;
                          color: $white;
                        }

                        &:active {
                          color: $newDarkGrey;
                          background-color: $wellHiddenBackgroundColor;
                          border: solid 1px $focusedElementBorderColor;
                          box-shadow: $glowShadow;
                          outline: none;
                        }

                        &.unselected {
                          background-color: $wellHiddenBackgroundColor;
                          color: $newDarkGrey;
                          &:hover {
                            background-color: $wellDefaultHoverColor;
                            color: $white;
                          }

                          &:active {
                            background-color: $wellHiddenBackgroundColor;
                          }
                          &:focus {
                            box-shadow: $glowShadow;
                            border: solid 1px $focusedElementBorderColor;
                            outline: none;

                            &:hover {
                              color: $white;
                              &:active {
                                color: $newDarkGrey;
                              }
                            }
                          }

                          &.well-highlighted {
                            background-color: $focusedElementBorderColor;
                            color: $white;
                            width: 100%;
                            height: 20px;
                          }
                        }

                        &.excluded {
                          position: relative;
                          background-color: $white;

                          &:after {
                            position: absolute;
                            left: -1px;
                            top: 7px;
                            transform: rotate(-17deg);
                            height: 1px;
                            background: $excludedWellSlashColor;
                            content: '';
                            width: 62px;
                            display: block;
                          }
                          &:hover {
                            background-color: $white;
                            color: $newDarkGrey;
                          }
                        }

                        &.well-highlighted {
                          background-color: $focusedElementBorderColor;
                          color: $white;
                          width: 100%;
                          height: 20px;
                        }

                        &.btn-default {
                          background: #ececec;
                          &:hover {
                            background: #cfcfcf;
                          }
                        }
                        &.no-data {
                          position: relative;
                          cursor: default;
                          background: $white;
                          color: $newDarkGrey;
                          border: dashed 1px #c9d2e0;

                          &:hover {
                            background: $white;
                            color: $white;
                          }
                          &:active {
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .pcr-qc-data {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            min-width: 480px;
            .button-group {
              margin: 10px 0 2px 0;
              padding-right: 8px;

              #save-results-button {
                min-width: 110px;
              }
            }

            .br-data-table {
              table {
                padding: 3px 3px 0px 3px; // padding needed so button focus outline is not hidden
                border-spacing: 0;
                width: 100%;
                position: relative;
                border-collapse: collapse;

                th,
                td {
                  text-align: left;
                  padding: 3px;
                }
              }
            }
          }
        }
      }
    }
    .pcr-run-name {
      font-family: $rubikFontFamily;
      color: $newDarkGrey;
      font-weight: 400;
      font-size: $secondaryFontSize;
      margin-right: 16px;
      @include ellipsis-text;
    }
    .pcr-data-color-circle {
      height: 6px;
      width: 6px;
      background-color: red;
      border-radius: 50%;
      display: inline-block;
    }
    .pcr-run-plate {
      display: flex;
      flex-grow: 1;
      overflow: auto;

      #plate-display {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
        background-color: #fafafa;
        margin-bottom: 16px;

        .toolbar {
          display: flex;
          padding: 12px 26px 0 42px;
          min-height: 72px;
        }
        .plate-table {
          flex-grow: 1;
        }
      }

      .left-icons,
      .right-icons {
        flex: 1;
        display: flex;
        align-items: flex-start;
      }

      .right-icons {
        justify-content: flex-end;
      }
    }
    .newer-version-icon {
      margin-right: 16px;
      height: 24px;
    }
  }

  .ghost {
    margin-right: 7px;
  }
}