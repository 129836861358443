@import '../../frontend-common-libs/src/scss/definitions';

.createApiTokenFormContainer {
  display: grid;
  grid-template-rows: auto auto;

  .errorText {
    margin: 0;
    font-size: 11px !important;
    font-weight: 500 !important;
    text-align: right !important;
    color: $invalidColor !important;
  }

  & * label {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.31;
    letter-spacing: normal;
    color: $newDarkGrey;
  }

  & * textarea {
    resize: none;
    border: 1px solid $inputBorderColor;
    background-color: $white;
    border-radius: 3px;
    height: 90px;
    padding: 5px;
  }

  form {
    grid-row: 1/2;

    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 70% auto;
    grid-gap: 32px;

    .descriptionContainer {
      grid-column: 1/2;
      grid-row: 1/2;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .expirationContainer {
      grid-column: 2/3;
      grid-row: 1/2;

      display: grid;
      grid-template-rows: min-content 1fr 1fr;

      select {
        border: 1px solid $inputBorderColor;
        border-radius: 3px;
        height: 30px;
        padding: 5px;
        appearance: none;
        -webkit-appearance: none;
        background-position: right 50%;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
        padding-right: 1.5em;
      }
    }

    .generateTokenContainer {
      grid-column: 1/3;
      grid-row: 2/3;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .apiTokenContainer {
    grid-row: 2/3;

    display: flex;
    flex-direction: column;
    border-top: 1px solid $newMediumGrey;
    margin-top: 46px;
    padding-top: 18px;

    .copyApiTokenWarning {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      margin-bottom: 7px;
      font-family: $rubikFontFamily;

      span {
        font-weight: 500;
      }
    }

    textarea {
      position: relative;
      padding-right: 45px;
    }
  }
}
