.pcr-protocol-gradient-step-edit {
  display: flex;

  .edit-controls-container {
    flex: 1;
  }

  .edit-controls-container li {
    min-width: 49%;
  }

  .row-item {
    width: 56px;
    display: flex;
    justify-content: space-between;
    margin: 5px 0 6px 0;

    div {
      height: 26px;
      font-size: 12px;
      display: flex;
      align-items: center;

      &:first-child {
        width: 9px;
        align-content: flex-start;
        color: #000000;
      }

      &:last-child {
        width: 41px;
        justify-content: center;
      }

    }
  }
}
