@import "../../src/frontend-common-libs/src/scss/definitions";

.protocol-template-row {
  padding-top: 5px;
  padding-right: 15px;
  border-bottom: solid 1px #f5f5f5;
}

.protocol-template-list {
  padding: 0px 15px 15px;
}

.protocol-template-list-header {
  border-bottom: solid 1px #dfdfdf;
  font-size: 12px;
  font-weight: 900;
  color: $titleTextColor;
  padding-top: 5px;
  padding-right: 15px;
  height: 25px;
}

.protocol-template-scroll-area {
  overflow-y: auto;
  max-height: calc(100vh - 151px);
  min-height: calc(100vh - 151px);
}

.protocol-template-column {
  display: inline-block;
  width: 140px;
  padding-left: 15px;
}

.protocol-template-name-col {
  @include ellipsis-text;

  min-width: 140px;
}

.protocol-parent-flex {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
}

.protocol-left-flex {
  text-align: left;
  min-width: 140px;
  flex-grow: 1;
}

.protocol-right-flex {
  text-align: right;
  min-width: 420px;
}
