@import "../../../frontend-common-libs/src/scss/definitions";

.menuItem {
  outline: none;
  height: 23px;
  max-height: 23px;
  padding: 15px 14px;
  display:flex;
  align-items: center;
  span {
    outline: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:focus{
    outline: none;
  }
  &:hover span {
    color: $lightblue;
  }
  &:focus span {
    color: $lightblue;
  }
  &:hover a {
    text-decoration: none;
  }
}
