@import "../../../../../frontend-common-libs/src/scss/definitions";

.notification-icon {
    position: relative;
    font-size: 10px;
    color: white;
    background: $errorRed;
    border-radius: 15px;
    text-align: center;
    width: fit-content;
    min-width: 15px;
    height: 15px;
    padding-left: 3px;
    padding-right: 3px;
}
