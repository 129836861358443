@import '../../../src/frontend-common-libs/src/scss/definitions';
@import '../../../src/frontend-common-libs/src/components/common/dialogs/styles/modal-dialog-v2';

.move-file-modal {
    @extend %modal-dialog-style-v2;
    @include modal-dialog-width(435px);

    .modal-header {
        background: $toolbarBackgroundColor;
        padding-top: 15px;
    }

    .modal-footer {
        @extend %space-buttons;
        background: $toolbarBackgroundColor;
        padding: 15px 32px;
        border-top: none;
    }

    .new-btn {
        &.ghost {
            background: $toolbarBackgroundColor;
            &:active, &:hover {
                background-color: $toolbarBackgroundColor;
            }
        }
    }
}

.move-file-panel {
    display: flex;
    flex-direction: column;
    margin: -15px;
    padding: 0 32px;
    background: $toolbarBackgroundColor;

    .top-row {
        margin-top: 16px;
        font-size: 14px;
        color: $newDarkGrey;
    }

    .project-list {
        display: flex;
        flex-direction: column;
        min-height: 280px;
        max-height: 208px;
        overflow-y: auto;
        background: $white;
        margin: 16px 0 12px 0;
        border: solid 1px $borderGrayColor;

        .project-row {
            min-height: 46px;
            padding-left: 8px;
            padding-right: 8px;
            display: inline-flex;
            align-items: center;

            &:hover {
                background-color: $rowHover;
            }

            &:focus {
                outline: none;
                border-top: 1px solid $focusedElementBorderColor;
                border-bottom: 1px solid $focusedElementBorderColor;
            }

            .role-image, .loading-spinner {
                margin-left: auto;
            }
        }

        .project-row-selected {
            background: $tableRowBackgroundColor;
        }

        .project-icon {
            margin-right: 6px;
            height: 28px;
        }

        .project-icon-disabled {
            @include convertToDisabledColor;
        }

        .project-name {
            color: $primaryLinkColor;
            font-size: 14px;
        }

        .project-row-disabled {
            color: $disabledLinkColor;
        }
    }

    .warn-msg {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;
        min-height: 60px;

        .warn-icon {
            align-self: flex-start;
            margin-right: 10px;
        }
    }
}