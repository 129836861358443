@import '../../src/frontend-common-libs/src/scss/definitions';
$selectionBorderWidth: 3px;
$plateBorder: 1px solid $inputBorderColor;
$colHeaderZIndex: 10;
$rowHeaderZIndex: 9;

.template-run{
  margin-top: 12px;
}

.plate-table {
  overflow: auto;
  user-select: none;
  -webkit-user-select: none;

  .table-well {
    padding: 20px 12px 0px 12px;

    .row-well-table,
    .header-table-well {
      display: flex;
      padding-left: 20px;
    }

    .col-headers-row {
      position: absolute;
      z-index: $colHeaderZIndex;
      margin-top: -20px;
      width: calc(100% - 23px);
    }

    .row-well-table {
      position: relative;

      .row-header {
        position: absolute;
        z-index: $rowHeaderZIndex;
        margin-left: -20px;

        &:first-child {
          height: 100%;
        }

      }

      &:nth-child(2) {
        .cell-well {
          border-top: $plateBorder;
          &:nth-child(1) {
            border-top: unset;
          }
        }
      }

      .cell-well {
        &:nth-child(2) {
          border-left: $plateBorder;
        }
      }
    }

    .header-table-well {
      .cell-well {
        min-height: unset;
        height: 18px;
        line-height: 18px;
        border-radius: 3px 3px 0 0;
        box-shadow: inset 0px 2px 1px -1px $inputBorderColor,
          inset -2px 0px 1px -1px $inputBorderColor,
          inset 2px 0px 1px -1px $inputBorderColor;
        border-right: 1px solid $white;
        border-bottom: $plateBorder;
        font-size: 12px;
        font-weight: 300;
        color: $lightblue;
        cursor: pointer;

        &:first-child {
          box-shadow: none;
          border-right: 0;
          background-color: transparent;
        }

        &:hover {
          box-shadow: inset 0px 2px 0px -1px $secondaryDarkBlue,
            inset -2px 0px 0px -1px $secondaryDarkBlue,
            inset 2px 0px 0px -1px $secondaryDarkBlue;
        }

        &:active {
          background-color: $secondaryDarkBlue;
          color: $white;
        }
      }
    }

    .select-all {
      position: absolute;
      margin-right: 2px;
      margin-left: -20px;
      z-index: $colHeaderZIndex;

      .select-all-outer {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $inputBorderColor;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background-color: $white;
        cursor: pointer;

        .select-all-inner {
          border: 1px solid $secondaryDarkBlue;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }

        &:hover {
          border: 1px solid $secondaryDarkBlue;

          .select-all-inner {
            border: 1px solid $secondaryDarkBlue;
          }

          &:active {
            background-color: $secondaryDarkBlue;
            .select-all-inner {
              background-color: $white;
              border-color: $white;
            }
          }
        }
      }
    }

    .cell-well {
      min-height: 53px;
      min-width: 65px;
      flex: 1 1 0px;
      text-align: center;
      border-bottom: $plateBorder;
      border-right: $plateBorder;
      background-color: $white;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        pointer-events: none;
        border: 0 solid $lightblue;
      }

      &:first-child {
        height: 100%;
        flex: none;
        min-width: 18px;
        font-size: 12px;
        font-weight: 300;
        color: $lightblue;
        border-radius: 3px 0 0 3px;
        box-shadow: inset 0px 2px 1px -1px $inputBorderColor,
          inset 0px -2px 1px -1px $inputBorderColor,
          inset 2px 0px 1px -1px $inputBorderColor;
        border-right: $plateBorder;
        border-bottom: 1px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          box-shadow: inset 0px 2px 0px -1px $secondaryDarkBlue,
            inset -2px 0px 0px -1px transparent,
            inset 2px 0px 0px -1px $secondaryDarkBlue;
          border-bottom: 1px solid $secondaryDarkBlue;
        }

        &:active {
          background-color: $secondaryDarkBlue;
          color: $white;
        }
      }
      &.add-left-selection-border {
        &:after {
          border-left-width: $selectionBorderWidth;
        }
      }
      &.add-right-selection-border {
        &:after {
          border-right-width: $selectionBorderWidth;
        }
      }
      &.add-top-selection-border {
        &:after {
          border-top-width: $selectionBorderWidth;
        }
      }
      &.add-bottom-selection-border {
        &:after {
          border-bottom-width: $selectionBorderWidth;
        }
      }
    }
  }

  .well-unit {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    padding: 7px 4px;
    font-size: 11px;
    background-color: white;
    cursor: default;
    height: 100%;
    color: $newDarkGrey;

    &.active {
      background-color: rgba(226, 242, 255, 0.8);
    }
  }

  .well-content-box {
    width: 100%;
  }

  .well-target {
    margin-top: 3px;
    border-radius: 6px;
    border: solid 2px #7aa3db;
    background-color: #eef5ff;
    width: 100%;
    height: 18px;
    word-break: break-all;
    overflow: hidden;
  }

  .well-target-50:nth-child(odd) {
    margin-left: 3px;
  }

  .well-target-50 {
    flex-basis: calc(50% - 4px);
  }
}

.plate-edit-panel {
  font-family: $rubikFontFamily;
  background-color: white;
  min-width: 489px;
  max-width: 489px;
  height: unset; // 100% Height for safari sizes to contents, not space available.
  overflow: auto;
  padding: 22px 15px 13px 15px;
  border-radius: 0 0 3px 3px;
  border-style: solid;
  border-color: $newBorderGrayColor;
  border-width: 0 1px 1px 1px;
  margin-bottom: 16px;
  margin-right: 14px;

  .error-message {
    word-break: break-word;
    font-size: 12px;
    color: $invalidColor;
    white-space: normal;
  }

  #plate-error {
    padding: 0px;

    button {
      color: inherit;
    }
  }

  .controls-container {
    border-radius: 5px;
    box-shadow: $mediumShadow;
    background-color: #e2f2ff;
    padding: 12px;

    .label-content,
    .control-content {
      max-width: 132px;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    .label-content {
      margin-bottom: 2px;
    }

    .swatch-wrapper {
      display: flex;
      align-items: center;

      .color-picker {
        width: 25px;
        height: 25px;
        border-radius: 3px;
        border: solid 1px $white;
        margin-right: 4px;
      }

      .no-color-icon {
        background-image: url('../img/no-color-slash.svg');
        background-color: $white;
      }

      .br-select {
        width: 103px;
      }
    }

    .control-content {
      &:focus {
        outline: none;
      }
      .form-group {
        margin-bottom: 0px; /* override bootstrap */
        input:not(.br-select-display) {
          border-radius: 3px;
          font-family: $rubikFontFamily;
          font-size: 11px;
          color: $secondaryGreyColor;
        }
        &:focus {
          outline: none;
        }
      }
      .form-control:not(.br-select-display) {
        height: 30px;
        padding: 0 0 0 8px;
      }

      input.display-as-label {
        border: none;
        font-family: $rubikFontFamily;
        font-size: 11px;
        color: $secondaryGreyColor;
        padding: 8px;
        background-color: transparent;
        box-shadow: none;
        cursor: default;
      }

      .br-select .br-select-menu {
        max-height: 224px; /* based on sample type height that shows all options without scroll */
      }

      #well-type.br-select .br-select-menu {
        width: 160px;
      }
    }

    hr {
      border: 0;
      border-top: 1px solid #b2bec8;
    }

    .flex-wrapper,
    hr {
      margin-bottom: 15px;
    }

    .header {
      margin-bottom: 0;
    }

    .btn-noborder {
      margin: 0;
      align-self: center;
      display: flex;
      text-decoration: none;
      padding: 2px;
      margin-left: -2px;
    }

    .plate-remove-btn {
      width: 15px;
      height: 15px;
      background-image: url('../img/plate-minus-icon-default.svg');
      background-size: cover;
      background-position: center;

      &:hover {
        background-image: url('../img/plate-minus-icon-active.svg');
      }

      &:focus {
        outline: 1px solid $focusedElementBorderColor;
        outline-offset: 2px;
        box-shadow: none;
      }

      &:hover:focus {
        outline: none;
      }
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 0.88;
    color: $newDarkGrey;
    margin: 0 0 14px;
  }

  p {
    font-size: 12px;
    line-height: 1.42;
    color: $newDarkGrey;
  }

  #samples-input-list {
    overflow: auto;
    resize: none;
    padding: 9px 8px;
    font-size: 11px;
    border: 1px solid $inputBorderColor;
    border-radius: 3px;
    box-shadow: inset 0 1px 4px 0 $boxShadowColor;
    width: 174px;
    height: 240px;
    white-space: pre;


    &:focus {
      border: solid 1px $focusedElementBorderColor;
      box-shadow: 0 1px 4px 0 $boxShadowColor inset, 0 1px 1px rgba(0, 0, 0, 0.08) inset, 0 0 8px $focusedElementBorderColor;
      outline: none;
    }
  }
}

.plate-setup-controls {
  font-family: $rubikFontFamily;
  display: inline-flex; /* so row does not take up entire width */
  flex-direction: column;
  color: $newDarkGrey;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;

  .plate-controls-label {
    display: block;
    margin-bottom: 3px;
  }

  label {
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    font-weight: normal;
    margin-right: 16px;
    margin-bottom: 0px;

    &:last-child {
      margin-right: 0;
    }
  }

  label:focus {
    outline: none;
  }

  #wells-size,
  #wells-type {
    display: inline-flex;
  }

  .scan-mode,
  .run-plate-size,
  .run-plate-type {
    margin-bottom: 28px;
  }

  .scan-mode {
    .dropdown {
      width: 190px;
      height: 25px;
      font-family: $rubikFontFamily;
      button {
        font-size: 13px;
        padding: 0 8px;
      }
      .dropdown-toggle {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        align-items: center;
      }
      .dropdown-menu {
        width: 100%;
        li a:focus {
          outline: -webkit-focus-ring-color auto 2px;
        }
      }
    }
  }
}

.plate-edit-content {
  flex: auto;

  h4 {
    font-family: $rubikFontFamily;
    font-size: 16px;
    font-weight: 500;
    color: $newDarkGrey;
    margin: 0 0 20px 0; /* reset default margins */
  }

  #plate-layout-dropdown.br-select .br-select-menu {
    width: 300px;
    max-height: 324px;
  }

  #replicate-number {
    font-size: 11px;
  }
}

.plate-edit-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  &.align-right {
    justify-content: flex-end;
  }
}

#plate-error-list {
  max-width: none;
  .popover-content {
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        padding: 2px 0;
      }
    }
  }
}

#copy-plate-link-btn {
  display: inline-flex;
}
