@import '../../../../frontend-common-libs/src/scss/definitions';

.run-protocol {
  margin-top: 18px;

  .protocol-label {
    font-size: 16px;
    line-height: 0.88;
    color: $newDarkGrey;
    margin-left: 28px;
    margin-bottom: 8px;
  }

  .protocol-name {
    margin-top: 14px;
  }
}
