$primaryColor: #00a650;

.pageTitle {
  font-weight: 500;
  margin-left: 5px;

  &:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: $primaryColor;
    border-radius: 50%;
    margin: 3px 5px;
  }
}
