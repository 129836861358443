@import 'src/frontend-common-libs/src/scss/definitions';

.details-header-value {
  display: flex;
  flex-direction: column;
  padding: 7px 0px 11px 0px;

  .header {
    color: $headerGreyColor;
  }

  .value {
    word-break: break-word;
  }
}

