@import 'definitions';

html,
body {
  height: 100%;
}

body {
  font-family: $rubikFontFamily;
  color: #3b404a !important;
}

:focus {
  outline: $focusedElementBorderColor auto 1px;
}

img {
  user-drag: none; //stylelint-disable-line property-no-unknown
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bold-font {
  font-weight: 700;
}

.nopad {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.flex-column-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cursor-own {
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
    min-width: 400px;
  }

  .page-content {
    flex-grow: 1;
    display: flex;
    background: #f5f5f8;

    .right-panel {
      flex-grow: 1;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

#alt-content {
  animation: delayMessage 0s 1s forwards;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@keyframes delayMessage {
  to {
    visibility: visible;
  }
}

.left-navbar {
  flex: 0 0 51px;
  background: $leftBarBackground;
  border-right: solid 1px $borderGrayColor;
}

.options-list-wrapper {
  background: #fff;
  min-width: 150px;
  position: absolute;
  top: 0;
  right: 0;
  left: auto; //override .dropdown-menu
  padding: 10px 0;
  z-index: 22;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.36);

  li {
    font-size: 14px;

    &:hover,
    &:active {
      background: #ebf7f0;
    }

    a {
      padding: 10px 15px;
      margin: 0px;
      max-width: none;

      &:hover,
      &:focus {
        background-color: #ebf7f0;
      }
    }
  }
}

.own-modal .form-group label {
  color: #888;
}

/* Bugfix for older Webkit, including mobile Webkit.
 * https://css-tricks.com/webkit-sibling-bug/
*/
.toggle {
  -webkit-animation: bugfix infinite 1s;
}

@-webkit-keyframes bugfix {
  from {
    position: relative;
  }

  to {
    position: relative;
  }
}

.green-color {
  color: $primaryColor;
}

.h-100 {
  overflow-y: auto;
  max-height: calc(100vh - 126px);
  min-height: calc(100vh - 126px);
}

.parent-flex {
  display: flex;
  align-items: center;
}

.left-flex {
  text-align: left;
}

.right-flex {
  text-align: right;
}

.flex-grow-1 {
  flex-grow: 1;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-5 {
  margin-left: 5px;
}

.display-box .display-box-col .form-control {
  border-radius: 2px;
}

.own-modal {
  .form-group.with-border .form-control {
    border: 1px solid #ddd;
    display: flex;
  }

  .modal-content {
    border-radius: 0;
  }

  .border-own {
    margin-bottom: 14px;
  }

  .modal-footer {
    border-top: 2px solid rgba(229, 229, 229, 0.63);
  }
}

.right-margin-15 {
  margin-right: 15px;
}

/* lsg screen */

.white-bg {
  background: #fff !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

.heading-title {
  font-size: 12px;
  font-weight: 500;
  color: $titleTextColor;
  text-transform: uppercase;
  * {
    text-transform: none;
  }
  span {
    margin-left: 3px;
  }
}

/* media code */

@media (min-width: 768px) {
  .unauth-header-menu-list {
    display: flex;
    align-items: center;
  }

  .margin-right-md {
    margin-right: 18px;
  }
}

.right-options-list-wrapper.options-list-wrapper {
  left: auto;
  right: 0;
  top: calc(100% + 10px);

  li {
    text-align: left;
    color: #161616;
  }
}

.top-bar-wrapper {
  padding-top: 8px;
  padding-bottom: 12px;
}

.white-bg-shadow {
  background-color: #fff;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.08);
}

.display-box .table td {
  border: none;
}

.fa-times.color-red:hover {
  color: red;
}

.blue-color {
  color: #4a90e2;
}

.red-color {
  color: #d0021b;
}

.purple-color {
  color: #9013fe;
}

#display-box-toggle .fa {
  margin-right: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.border-top-gray {
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.08);
}

.text-bold {
  font-weight: bold;
}

@media (min-width: 768px) {
  .equal-list-wrapper li {
    width: 49%;
  }

  .protocol-step-modal {
    padding-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .equal-list-wrapper li {
    width: 33%;
  }
}

@media (max-width: 768px) {
  .equal-list-wrapper li {
    width: 100%;
  }
}

/* switch css */

.gray-bg {
  background: #f9f9f9;
}

.top-bar-wrapper .btn-primary {
  min-height: 40px;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-36 {
  margin-bottom: 36px !important;
}

.margin-top {
  margin-top: 15px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.border-top-own {
  border-top: 2px solid #eaeaea;
  padding-top: 15px;
}

.empty option[disabled]:first-child {
  display: none;
}

.text-ellipsis {
  @include ellipsis-text;
}

.ellipsis-width-200 {
  max-width: 200px;
}

.small-text {
  font-size: 90%;
}

.fa-spin-center {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999;
}

.regular-link {
  color: $primaryColor;
  font-weight: 700;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
}

.list-no-dots {
  list-style-type: none;
  margin: 0;
}

.color-black {
  color: black;
}
.profile-wrapper {
  padding: 0 0 20px 40px;
  height: 100%;
  overflow: auto;
}

.flex-bullseye {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
}

.no-border-button {
  @extend %no-border-button;
}

.no-focus-outline-on-click {
  @extend %no-focus-outline-on-click;
}

.no-focus-outline {
  &:focus {
    outline: none;
  }
}

.flex-wrapper {
  display: flex;
}

.flex-item {
  flex: 1;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}
