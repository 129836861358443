.run-protocol-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $white;
  padding-top: 18px;
  margin: 3px 14px 14px 12px;
  border: 1px solid $newBorderGrayColor;
  border-top: none;
  border-radius: 0 0 3px 3px;

  .button-group {
    align-items: flex-end;
  }

  #button-placeholder {
    padding-right: 15px;
    padding-bottom: 14px;
  }
}

.run-protocol-header {
  background: white;
  font-family: $rubikFontFamily;
  margin-bottom: auto; /* push .button-group to bottom when there is no .protocol-details */
  padding: 0 16px 0px 28px;

  .run-protocol-select {
    margin-bottom: 5px;
    .form-label-error {
      margin: 0px;
    }
  }

  .step-number {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 8px 0;
  }

  .label-and-error {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    max-width: 1175px;

    .error-message {
      font-size: 12px;
      font-weight: 400;
      color: #ff3d55;
    }
  }
}

.run-protocol-select {


  .br-select {
    max-width: 1175px;
  }

  .br-select-button {
    .br-select-display {
      font-weight: 500;
    }
  }

  .br-select-menu {
    max-height: 200px;
    overflow-y: auto;
  }
  .infinite-scroll-spinner {
    text-align: center;
    font-size: 2em;
    line-height: 2em;
    pointer-events: none;
  }
}

.protocol-name-label {
  font-family: $rubikFontFamily;
  font-size: 16px;
  line-height: 0.88;
  color: $newDarkGrey;
  margin-left: 28px;
}
.protocol-name-text {
  margin-top: 16px;
  margin-bottom: 15px;
}

.secondary-button-link {
  font-family: $rubikFontFamily;
  font-size: 13px;
  background-color: transparent;
  border: none;
  color: $lightblue;
  margin: 0px;
  padding: 0px;
  margin-left: 5px;
  &:hover {
    text-decoration: underline;
  }
}

.display-horizontal {
  display: inline-flex;
  width: 100%;
}

.stretch-horizontal {
  flex-grow: 1;
}

.align-button {
  align-self: flex-end;
  margin-left: 5px;
}
