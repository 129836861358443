@import '../../../frontend-common-libs/src/scss/definitions';

#fleet-instrument-toggle-options {
  :global {
    .br-radio-label {
      color: $headerGreyColor;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin: 0 50px 0 0;
      padding: 0 6px 4px 6px;
      text-decoration: none;

      &::after {
        border: none;
      }

      &.radio-checked {
        color: $newDarkGrey;
        border-bottom: 2px solid $primaryLinkColor;
      }

      &:hover,
      &:focus,
      &:focus-within {
        text-decoration: none;
        color: $newDarkGrey;
        outline: none;
      }
    }
  }
}
:global(#instrument-fleet hr) {
  height: 1px;
  width: 100%;
  margin-top: 0;
  border-top: none;
  background-color: $borderGrayColor;
}