@import '../../../frontend-common-libs/src/scss/definitions';

.aria-auto-suggest-input {
  width: 100%;
  height: 40px;
  border: 1px solid $inputBorderColor;
  border-radius: 3px;
  cursor: pointer;
  display: flex;

  &:focus-within {
    border: solid 1px $focusedElementBorderColor;
    box-shadow: $glowShadow;
    outline: none;
    background: $white;
  }

  input:focus, input.form-control:focus {
    box-shadow: solid 1px $focusedElementBorderColor;
    border: solid 1px #c2c2c2;
    outline: none;
  }
}

.auto-suggest-input {
  display: flex;
  width: 100%;
  border-radius: 3px 0px 0px 3px;
  cursor: pointer;
  outline: none;
}

.dropdown-toggle {
  width: 30px;
  border: solid 1px $inputBorderColor;
  background: #F5F5F8;

  &:focus {
    outline: none;
  }
}

.auto-suggest-input-label {
  font-size: 12px;
  line-height: 1.67;
  text-align: left;
  color: #888;
  font-weight: 500;
  text-indent: 2px;
}
