@import '../../../../frontend-common-libs/src/scss/definitions';

@mixin base-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid $inputBorderColor;
  border-radius: 3px;
  height: 30px;
  width: 360px;
  background-color: $white;
  padding: 5px 8px 5px 5px;

  &:focus {
    border: solid 1px $focusedElementBorderColor;
    box-shadow: $insetShadow, $glowShadow;
    outline: none;
  }

  input[type='text'] {
    font-family: $rubikFontFamily;
    width: 100%;
    color: $newDarkGrey;
    height: 20px;
    padding-left: 8px;
    font-size: 11px;
    outline: none;
    box-shadow: none;
    border: none;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }

    &::placeholder {
      color: $placeholderTextColor;
      font-family: $rubikFontFamily;
    }

    @include ellipsis-text;
  }

  input#file-search-box {
    padding: 3px 8px 3px 3px;
    font-size: 14px;
  }

  #search-icon {
    margin-right: 6px;
    background-color: Transparent;
    outline: none;
    border: 1px solid transparent;

    img {
      width: 17px;
      height: 17px;

      &:active {
        outline: none;
      }
    }

    &:focus, &:hover:active {
      border: 1px solid $focusedElementBorderColor;
    }
  }

  #clear-search-icon {
    background-color: Transparent;
    outline: none;
    border: 1px solid transparent;

    img {
      width: 17px;
      height: 17px;

      &:active {
        outline: none;
      }
    }

    &:focus, &:active {
      border: 1px solid $focusedElementBorderColor;
    }
  }
}

.search-input {
  @include base-input();

  &-focus {
    @include base-input();
    border: solid 1px $focusedElementBorderColor;
    box-shadow: $insetShadow, $glowShadow;
    outline: none;
  }
}
