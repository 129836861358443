.br-data-table {
  font-family: $rubikFontFamily;
  font-size: 12px;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px $frameBorderGrayColor;
  padding: 12px 21px 0 11px;
  overflow: hidden;

  .br-table-row {
    height: 32px;
    width: 100%;
    border-bottom: 1px solid $newMediumGrey;
    align-items: center;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
    .br-table-cell {
      font-family: $rubikFontFamily;
      font-size: 12px;
      line-height: normal;
      @include ellipsis-text;
    }
  }

  table {
    thead {
      vertical-align: baseline;
      th {
        position: sticky;
        top: 0;
        background-color: $white;
        padding: 0;
        height: 45px;
      }
      &,
      button {
        margin-bottom: 5px;
        padding: 0;
        width: 100%;
        text-transform: uppercase;
        color: $headerGreyColor;
        font-weight: 500;
      }
      &,
      .sort-column {
        font-weight: bold;
        width: 100%;
        border: none;
        background: transparent;
        text-align: left;
        &:active {
          text-decoration: none;
        }
        &:focus {
          box-shadow: none;
          outline: 1px solid rgba(255, 102, 0, 0.7);
          outline-offset: 2px;
        }
        &.selectable:hover,
        &.selectable:focus {
          text-decoration: underline;
        }
        &.sort-up,
        &.sort-down {
          color: $secondaryDarkBlue;
        }
        &.sort-up:after,
        &.sort-down:after {
          @include hoverColorFilter;
          position: absolute;
          right: 5px;
        }
        &.sort-up:after {
          content: url(../img/arrow-up.svg);
        }
        &.sort-down:after {
          content: url(../img/arrow-down.svg);
        }
      }
      .well-column > button {
        width: 54px;
        &:after {
          top: 0;
          left: 45px;
        }
      }
    }
  }

  .table-content {
    height: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar; // IE/Edge specific style for above scenario
    color: $newDarkGrey;
  }
}
