@import '../../frontend-common-libs/src/scss/definitions';

$tableRowDetailsLinkBackgroundColor: #fff9da;

#instrument-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  #instrument-list-title {
    font-size: 13px;
    color: $breadcrumbTextGrayColor;
  }

  .table-breadcrumb#breadcrumb-instruments {
    justify-content: space-between;
    padding: 0 22px 0 15px;
  }

  .flex-column-container#instruments-table-container {
    background-color: $secondaryColor;
    max-height: calc(100vh - 120px);
  }

  .base-table#instruments-table {
    @mixin icon-col {
      min-width: 60px;
    }

    @mixin model-col {
      min-width: 200px;
      /*  hide model  */
      @media only screen and (max-width: $tabletPortraitMaxWidth) {
        display: none;
      }
    }

    @mixin name-col {
      flex-grow: 1;
      min-width: 120px;
      padding: 0 8px;
    }

    @mixin status-col {
      width: 200px;
      min-width: 120px;
    }

    @mixin time-col {
      width: 200px;
      min-width: 120px;
    }

    @mixin expander-col {
      width: 30px;
      /*  hide expander  */
      @media only screen and (max-width: $tabletPortraitMaxWidth) {
        display: none;
      }
    }

    th,
    td:nth-of-type(1) {
      @include icon-col;
    }

    th,
    td:nth-of-type(2) {
      @include model-col;
    }

    th,
    td:nth-of-type(3) {
      @include name-col;
    }

    th,
    td:nth-of-type(4) {
      @include status-col;
    }

    th,
    td:nth-of-type(5) {
      @include time-col;
    }

    th,
    td:nth-of-type(6) {
      @include expander-col;
    }

    .cell-text {
      color: $newDarkGrey;
    }

    .name-cell {
      color: $primaryLinkColor;
    }

    .status-cell {
      display: inline-flex;
      text-transform: capitalize; // capitalize first letter
    }

    .status-img {
      width: 16px;
      height: 16px;
      background: inherit;
      margin-right: 6px;
    }

    .details-cell {
      margin: unset;
      width: 100%;
      align-self: stretch;
      justify-self: stretch;
    }

    .show-on-hover {
      visibility: hidden;
    }

    tbody {
      tr {
        &:hover,
        &:focus-within {
          .show-on-hover {
            visibility: visible;
          }
        }
      }
    }

    .details-row {
      height: 70px;
      display: flex;
      margin-top: 14px;
      margin-bottom: 14px;

      .instrument-details {
        display: flex;
        height: 100%;
        flex-direction: row;

        .run-panel {
          flex-direction: column;
          display: flex;
          border-radius: 7px;
          margin-left: 16px;
          padding: 7px 16px;
          width: 346px;
        }

        .run-panel-background {
          background-color: $tableRowDetailsLinkBackgroundColor;
          line-height: 0px;
        }

        .run-details {
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          margin-bottom: 16px;
          align-items: center;
          line-height: 0px;
        }

        .button-group {
          margin-top: unset;
          margin-right: 112px;
        }

        .details-panel {
          flex-direction: column;
          display: flex;
          flex-grow: 1;
          margin-left: 16px;
          padding: 7px 16px;
          max-width: 28%;
          border-radius: 7px;
          background-color: $tableRowDetailsBackgroundColor;
        }

        .title-label {
          font-size: 14px;
          color: $newDarkGrey;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 20px;
        }

        .details-line {
          display: flex;
          flex-direction: row;
          margin-top: 7px;
          margin-left: 30px;
        }

        .category {
          width: 100%;
          margin-right: 10px;
          text-align: right;
          font-size: 12px;
          color: $tableRowDetailsTitleColor;
        }

        .category-value {
          width: 100%;
          font-size: 12px;
          color: $newDarkGrey;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 17px;
        }

        .separator {
          margin-right: 10px;
        }

        .link-img {
          margin-right: 5px;
        }

        .link-action {
          display: flex;
          align-items: center;
          color: $primaryLinkColor;
          margin-right: 10px;
        }
      }
    }
  }

  .expand-collapse-button {
    width: 16px;
    height: 16px;
    margin: 1px;
    cursor: pointer;
  }
}

.instrument-model-label {
  white-space: nowrap;
  height: 14px;
  font-size: $primaryFontSize;
  font-weight: 500;
  color: $secondaryGreyColor;
}

.instrument-name-label {
  display: flex;
  height: 18px;
  color: $primaryLinkColor;

  .with-quotes {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    max-width: 85px;
  }

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
}

.instrument-name-label-block {
  display: flex;
  height: 18px;
  color: $primaryLinkColor;
  max-width: 145px;

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    max-width: 85px;
  }

  .name:before {
    content: open-quote;
  }

  .closing-quotes:after {
    content: close-quote;
  }

  .block {
    padding-left: 3px;
  }
}

.recent-instruments-list-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  #recent-instrument-list-title {
    font-size: 15px;
    font-family: $rubikFontFamily;
    color: $headerDarkColor;
    font-weight: 500;
    margin-left: 30px;
    margin-top: 0px;
    margin-bottom: 0;
  }

  .recent-instrument-list {
    height: 75px;
    margin: 10px;
    background-color: $leftBarBackground;
    border-radius: 3px;
    box-shadow: $primaryBoxShadow;

    #instruments-error > span {
      margin-right: 10px;
    }

    #instruments-error,
    #no-instruments-message,
    .loader {
      height: 100%;
      width: 100%;
      text-transform: capitalize;
    }
  }

  .recent-instrument-list .recent-instrument-item {
    height: 75px;
    flex: 1 0 200px;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    align-items: center;
    max-width: 280px;

    &:first-child {
      .icon {
        border-left: 0;
      }
    }

    .icon {
      padding-left: 10px;
      max-width: 62px;
      border-left: 1px solid $newMediumGrey;

      img {
        width: 51px;
        height: 54px;
        object-fit: contain;
      }
    }

    .details {
      width: 100%;
      margin-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      text-align: left;
      font-size: $secondaryFontSize;
      font-family: $rubikFontFamily;

      .label-status {
        margin-top: 2px;
        height: 18px;
        color: $newDarkGrey;
      }
    }
  }

  .recent-instrument-list .recent-instrument-item:nth-child(n+8) {
    display: none;
    pointer-events: none;
  }
}

@media (max-width: 1440px) {
  .recent-instruments-list-container {
    .recent-instrument-list .recent-instrument-item:nth-child(n+5) {
      display: none;
      pointer-events: none;
    }
  }

  .instrument-name-label {
    .with-quotes {
      max-width: 115px;
    }
  }

  .instrument-name-label-block {
    .name {
      max-width: 115px;
    }
  }
}

@media (max-width: 1600px) {
  .recent-instruments-list-container {
    .recent-instrument-list .recent-instrument-item:nth-child(n+6) {
      display: none;
      pointer-events: none;
    }
  }

  .instrument-name-label {
    .with-quotes {
      max-width: 100px;
    }
  }

  .instrument-name-label-block {
    .name {
      max-width: 100px;
    }
  }
}

@media (max-width: 1850px) {
  .recent-instruments-list-container {
    .recent-instrument-list .recent-instrument-item:nth-child(n+7) {
      display: none;
      pointer-events: none;
    }
  }
}
