@import "../../../../scss/definitions";


%button-outline {
  outline: 1px solid $focusedElementBorderColor;
  outline-offset: 2px;
}

%space-buttons {
  .modal-footer-own {
    display: flex;
    justify-content: space-between;
  }
}

@mixin new-btn-active-state($backgroundColor, $textColor, $borderColor) {
  &:active {
    @extend %button-outline;
    background-color: $backgroundColor;
    color: $textColor;
    border-color: $borderColor;

    &[disabled] {
      background-color: $lightGray;
      outline: none;
    }
  }
}

@mixin modal-dialog-width($width) {
  .modal-dialog {
    width: $width;
  }
}

%modal-dialog-style-v2 {
  display: flex !important;

  .modal-dialog {
    margin: auto;
  }

  .modal-header-own {
    background: transparent;
    padding-top: 20px;
    padding-bottom: 12px;
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;

    h4 {
      grid-column: 1/2;
      font-size: 14px;
      font-weight: 500;
      color: $newDarkGrey;
    }

    .close {
      color: #0064B0;
      opacity: 1;
      background-color: initial;
      border-color: initial;
      justify-self: end;
      font-weight: normal;
      &:active, &:focus {
        @extend %button-outline;
        -webkit-box-shadow: none;
      }
    }
  }

  .new-btn {
    &.utility {
      @include new-btn-active-state($lightblue, $white, $lightblue);
    }

    &.ghost {
      @include new-btn-active-state($white, $secondaryDarkBlue, $secondaryDarkBlue);
    }
  }
}

.edit-file-dialog-style-v2 {
  @extend %modal-dialog-style-v2;
  @include modal-dialog-width(385px);

  .modal-body {
    padding: 0 24px 24px 24px;

    .form-field {
      height: 30px;
    }

    .form-label {
      margin: 22px 0 2px 0;
      color: $newDarkGrey;
      font-size: 13px;
      font-weight: normal;
    }
  }

  .modal-header {
    padding: 24px 0 12px 0;
    margin: 0 24px;
  }

  .modal-footer {
    @extend %space-buttons;
    padding: 16px 0 24px 0;
    margin: 0 24px;
  }
}

.ok-cancel-dialog-style-v2 {
  @extend %modal-dialog-style-v2;
  @include modal-dialog-width(380px);

  .modal-body {
    padding: 24px 24px 32px 24px;
    word-break: break-word;

    h4 {
      color: $newDarkGrey;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .modal-header {
    padding: 24px 0 12px 0;
    margin: 0 24px;
  }

  .modal-footer {
    @extend %space-buttons;
    border-top: none;
    padding: 0;
    margin: 0 24px 16px 24px;
  }
}