@import "../../scss/definitions";
$gray: #808080;
$bold: 500;


.user-preferences-details {
  padding-left: 12px;
  min-width: 300px;
}

.popover-content {
  padding: 0;
  .popover-section {
    padding-bottom: 10px;
    border-bottom: 1px solid #c6c6c6;
    margin-bottom: 10px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 1px;
    }

    .section-header,
    .sub-section,
    .sub-section .popover-label {
      margin-bottom: 12px;
    }

    .sub-section .popover-label:last-of-type {
      margin-bottom: 0px;
    }

    .sub-section {
      margin-left: 55px;
      .popover-label .popover-value {
        font-weight: $bold;
        font-size: $primaryFontSize;
        margin-left: 6px;
      }
      .popover-link {
        margin-left: 110px;
        color: $lightblue;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    #baseline-checkbox {
      flex-grow: 1;
      align-items: start;
    }

    & :last-child {
      margin-bottom: 0px;
    }
  }
}
