@import '../../../frontend-common-libs/src/scss/definitions';

.organizations-page {
  padding: 0 0 30px 0 !important;
  margin: 13px 47px 16px 20px;
  display: flex;
  flex-direction: column;

  .organizations-page-breadcrumb {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: -10px;

    .create-org-button {
      background: transparent;
      margin: 3px;
    }
  }

}