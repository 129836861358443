$primaryColor: #009141;

.react-datepicker-wrapper {
  align-self: flex-end;
}

.datePickerCustomInput {
  cursor: pointer;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  padding: 5px;
  background: url('../img/date-picker.svg') no-repeat right;
  background-size: 20px;
  width: inherit;
  height: inherit;

  &:focus {
    outline: none;
  }
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover, 
.react-datepicker__day--in-range:hover, 
.react-datepicker__month-text--selected:hover, 
.react-datepicker__month-text--in-selecting-range:hover, 
.react-datepicker__month-text--in-range:hover, 
.react-datepicker__quarter-text--selected:hover, 
.react-datepicker__quarter-text--in-selecting-range:hover, 
.react-datepicker__quarter-text--in-range:hover, 
.react-datepicker__year-text--selected:hover, 
.react-datepicker__year-text--in-selecting-range:hover, 
.react-datepicker__year-text--in-range:hover,
.datePickerSelectedDay {
  background-color: $primaryColor;
  color:white;
  border-radius: 47%;
  box-shadow: inset 0 0 0 1px white;
  border: 1px solid $primaryColor;
  margin: 0.066rem;
  width: 20px;
}

.react-datepicker__header {
  background-color: $primaryColor;
  padding-top: 6px !important;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: white;
  }
}



.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: $primaryColor;
}

.example-datepicker-class .react-datepicker__today-button {
  color: $primaryColor;
  background-color: white;
  border-top: 0px;
  text-align: right;
  margin-right: 20px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  top: 2px;
  border-color: white;
}


/**
 * The DatePicker component currently uses rem units for sizing, which can cause the component
 * to have a different size than its parent element. This affects both the font size and the
 * spacing of the calendar. 
 * In order to avoid this, we override the font size and spacing of the component here.
 */
.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.2rem !important;
  color: white;
}

.react-datepicker__navigation {
  top: 7px !important;
  outline: none !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}
