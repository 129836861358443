// Auth Only Component Styles

.formContainer {
  width: 392px;
  height: auto;
  margin: 0 auto 0 auto;
}

.changePassword {
  overflow: auto;
  height: 100%;
}