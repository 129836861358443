#user-preferences-popover {
  z-index: 3;
  top: 57px;
}

#user-preferences-popover-panel {
  padding-top: 0;
}

#user-preferences-left-panel {
  display: flex;
  flex-direction: column;
}

#notifications-popover-navbar, #user-preferences-popover-navbar {
  margin-bottom: 10px;
}

#notifications-popover-right-panel {
  padding-left: 0;
}

#user-preferences-sidebar {
  height: 100%;
}

.user-preference-sidebar-item {
  border: none;
  padding: 0;
  margin-bottom: 20px;

  &:focus {
    outline: none;
  }
}

.user-preferences-details {
  padding-left: 12px;
  min-width: 300px;
}

#user-preferences-notifications {
  background-color: inherit;
}

#notifications-popover-right-panel .notification-toolbar-separator {
  border-right: solid 0.01em #808080;
  margin: 0 10px 0 10px;
}

#notifications-popover-right-panel button label{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0064b0;
  margin-bottom: 0px !important;
  cursor: pointer;
}
#notifications-popover-right-panel .selected-device-type {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #3d3d3d !important;
}

#notifications-popover-right-panel .simple-popover-navigation-button {
  font-weight: 500;
  color: #3d3d3d !important;
}
