@import '../../../frontend-common-libs/src/scss/definitions';

.row {
  //BRIO3-2678: Repeated occurrences of the same simple selector increases specificity: https://www.w3.org/TR/selectors-3/#specificity, can
  //remove this once we decrease the specificity of some instrument list css rules
  &.row {
    height: 17px !important;
    background-color: transparent !important;
    cursor: text;
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-top: 4px;
    margin-bottom: 9px;

    &::after {
      display: none;
    }

    @mixin base-instrument-details-label {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
    }

    .label {
        @include base-instrument-details-label();
        min-width: 98px !important;
        margin-left: 0;
        margin-top: 4px;
        margin-right: 8px;
        text-align: right;
        font-size: 12px;
        line-height: 1.42;
        color: $tableRowDetailsTitleColor;
    }
    .value {
        @include base-instrument-details-label();
        min-width: 40px !important;
        @media only screen and (max-width: $tabletPortraitMaxWidth) {
          display: inline !important;
        }
        text-align: left;
        margin-top: 4px;
        margin-right: 2px;
        max-width: 110px;
        font-size: 12px;
        color: $newDarkGrey;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1.42;
      }
    }
}
