.wells-popover {
  min-width: 180px;
  .well-table-popover-info {
    font-size: 12px;
    .info-item {
      display: flex;
      .caption {
        flex: 0 0 80px;
        font-weight: bold;
      }
    }
  }
}

.selection-box-selectable-region.mousedown {
  .cell-well {
    pointer-events: none;
    &.table-headers,
    &#well-th-0 {
      pointer-events: auto;
    }
  }
}

.line-series-chart-header {
  align-items: flex-end;
  margin-bottom: -2px;
  $button-width: 25px;
  $button-offset: 9px;
  $title-offset: $button-width + $button-offset;

  .line-series-chart-title {
    font-family: $rubikFontFamily;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: $newDarkGrey;
    flex-grow: 1;
    z-index: 1000;
    margin-left: $title-offset; // this centers the title in the overall div
  }

  #pcr-chart-buttonmenu {
    cursor: pointer;
    z-index: 1000;
    right: -$button-offset;
    #buttonmenu-menu {
      right: 9px;
      top: 26px;
    }

    #buttonmenu-button {
      width: $button-width;
      height: $button-width;
      img {
        width: 17px;
        height: 17px;
        object-fit: contain;
      }
    }
  }
}

.analysis-setup {
  margin-right: 20px;
}

.toolbar-button {
  color: $lightblue;
  font-size: $primaryFontSize;
  font-family: $rubikFontFamily;
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    text-decoration: underline;
  }

  .toolbar-icon {
    margin-right: 4px;
    width: 20px;
    height: 20px;
    @include defaultColorFilter;
  }
}

.table-wrapper {
  overflow: auto;
  min-width: 460px;
}

.well-column {
  min-width: 73px;
  max-width: 73px;
}

.fluor-column {
  min-width: 73px;
  max-width: 73px;
}
.target-column {
  min-width: 84px;
  max-width: 84px;
}
.content-column {
  min-width: 84px;
  max-width: 84px;
}
.sample-column {
  min-width: 84px;
  max-width: 84px;
}
.cq-column {
  min-width: 35px;
  text-overflow: initial;
}

.temp-column {
  min-width: 96px;
  max-width: 96px;
}

.height-column {
  min-width: 102px;
  max-width: 102px;
}
