.forgot-password {
  overflow: auto;
  flex-grow: 1;
}

#change-password-message {
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
}
