.protocolAssignmentContainer {
  margin-top: 10px;
}

.protocolAssignmentText {
  font-family: Rubik, sans-serif;
  font-size: 14px;
  width: 335px;
}

.protocolAssignmentSelect {
  font-family: Rubik, sans-serif;
  font-size: 13px;
  width: 360px;
  margin-top: 4px;
}

.protocolAssignmentMenu {
  max-height: 400px;
}