@import '../../frontend-common-libs/src/scss/definitions';
.run-template-selector-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $white;
  padding-top: 18px;
  margin: 3px 14px 14px 12px;
  border: 1px solid $newBorderGrayColor;
  border-top: none;
  border-radius: 0 0 3px 3px;

  .button-group {
    align-items: flex-end;
  }

  #button-placeholder {
    padding-right: 15px;
    padding-bottom: 14px;
  }
}

.run-template-selector-header {
  background: white;
  font-family: $rubikFontFamily;
  margin-bottom: auto;
  padding: 0 16px 0px 28px;

  .run-template-select {
    margin-bottom: 5px;
    .form-label-error {
      margin: 0px;
    }
  }

  .label-and-error {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    max-width: 1175px;

    .error-message {
      font-size: 12px;
      font-weight: 400;
      color: #ff3d55;
    }
  }
}

.run-template-select{
  .br-select {
    max-width: 1175px;
  }

  .br-select-button {
    .br-select-display {
      font-weight: 500;
    }
  }

  .br-select-menu {
    max-height: 200px;
    overflow-y: auto;
  }
  .infinite-scroll-spinner {
    text-align: center;
    font-size: 2em;
    line-height: 2em;
    pointer-events: none;
  }
}