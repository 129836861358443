@import '../../../../frontend-common-libs/src/scss/definitions';

.createReservationsContainer {
  justify-content: space-between;

  .createReservationsTableContainer {
    display: flex;
    justify-content: space-between;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 0;
}

@mixin fleetPageButton {
  min-width: 0 !important;
  height: 31px;
  font-size: 14px !important;
  line-height: 20px;
}

.reserveButton {
  margin: 30px 0 16px 0;
  @include fleetPageButton
}