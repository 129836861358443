@import '../../../../src/frontend-common-libs/src/scss/definitions';

.members-table {
  display: flex;
  flex-direction: column;
  margin: 16px 16px 0 16px;
  background: $toolbarBackgroundColor;

  .members-header {
    color: $newDarkGrey;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 6px;
    border-bottom: solid 1px $headerGreyColor;
  }

  .member-rows {
    display: flex;
    flex-direction: column;
    background: $toolbarBackgroundColor;

    .member-row {
      display: flex;
      color: $newDarkGrey;
      font-size: 11px;
      height: 26px;
      align-items: center;
      border-bottom: 1px solid $separatorColor;

      &:hover {
        background-color: $rowHover;
      }

      .br-select {
        width: 110px;
        margin-left: auto;
      }

      .br-select-button {
        background: $toolbarBackgroundColor;
        border: none;
        height: 24px;
      }

      .br-select-display {
        color: $lightblue;
      }

      .br-select-menu-item {
        font-weight: normal;
        font-size: 12px;
      }

      .remove-user {
        margin-left: 8px;
        background: none;
        border: none;
      }
    }
  }

}
