@import "../../../frontend-common-libs/src/scss/definitions";

// Common Styles

.inputField {
  &.inputField {
    width: 100%;
    border: 1px solid $inputBorderColor;
    color: $newDarkGrey;
    border-radius: 3px;
    height: 30px;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 8px;
    font-size: 13px;
    margin: 0;

    &:focus {
      border: solid 1px $focusedElementBorderColor !important;
      box-shadow: $insetShadow, $glowShadow !important;
      -webkit-box-shadow: $insetShadow, $glowShadow !important;
      outline: none !important;
    }
  }
}
