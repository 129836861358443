@import '../src/frontend-common-libs/src/scss/definitions';

@mixin notification-text {
  font-family: $rubikFontFamily;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $newDarkGrey;
}

.complete-registration-container {
  display: flex;
  height: 100%;

  #biorad-icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: 25px;
  }

  #error-title, #loader-title {
    @include notification-text;
    font-weight: 500;
    margin-top: 40px;
  }

  #error-message, #loader-message {
    @include notification-text;
    font-size: 24px;
  }
  
  #error-verification-container,
  #link-resend {
    color: $primaryColor;
    font-weight: 500;
    margin-top: 10px;
    font-size: 24px;
  }
}

#verification-icon-header{
  display: flex;
  justify-content: center;
  margin-bottom: 81px;
  img:nth-of-type(1){
    margin-right: 15px;
  }
  img:nth-of-type(2){
    margin-left: 15px;      
  }
}

.center-content {
  display: flex;
  justify-content: center;
}
