$gray: #808080;

.settingsWrapper {
  margin: 16px 0;
  display: flex;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 115px);
}

.settingNavbar {
  text-align: center;
  width: 200px;
  min-width: 200px;
  border-right: thin solid $gray;
  margin: 0 20px 0 0;
  padding: 0 20px 0 20px;

  button label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #0064b0;
    margin-bottom: 0;
    cursor: pointer;
  }
}

.settingMenuButton {
  border: 0;
  background: #f5f5f800;
}

.settingContainerMenuItem {
  display: flex;
  justify-content: flex-end;

  label[aria-selected='true'] {
    font-weight: 500;
    color: #3d3d3d;
  }
}
