@import "../../../frontend-common-libs/src/scss/definitions";

.buttonmenu {
  position: relative;

  span#buttonmenu-button {
    height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      outline: 1px solid $focusedElementBorderColor;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }

  #buttonmenu-menu {
    background: $secondaryColor;
    border-radius: 3px;
    box-shadow: 0 0 6px 2px $shadowColor;
    outline: none;
    list-style-type: none;
    padding: 5px 0;
    position: absolute;
    z-index: 1000;

    .buttonmenu-item {
      outline: none;
      height: 23px;
      max-height: 23px;
      padding: 15px 14px;
      display:flex;
      align-items: center;
      span {
        outline: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:focus{
        outline: none;
      }
    }
  }
}

