$white: #ffffff;
$primaryColor: #009141;
$secondaryColorHighlight: rgba(0, 145, 65, 0.15);
$focusedColor: #40b6f3;

.modalHeader {
  background: $primaryColor;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $white;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;

  h4 {
    grid-column: 1/2;
  }

  button {
    color: $white;
    opacity: 1;
    background-color: initial;
    border-color: initial;
    justify-self: end;
  }
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.copyClipboardContainer {
  position: absolute;
  bottom: 45px;
  right: 30px;

  button {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
