@import '../../../../../frontend-common-libs/src/scss/definitions';

.actions {
  background-color: #e2f2ff;
  width: 191px;
  min-height: 237px;
  text-align: center;
  padding: 16px 10px;
  display: flex;
  flex-direction: column;

  h1 {
    color: $headerGreyColor;
    font-size: 14px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 4px;
    line-height: normal;
    letter-spacing: 0.5px;
  }

  .counter {
    color: $newDarkGrey;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 16px;
    line-height: 1.29;
    letter-spacing: normal;
  }

  .lidButtons, .actionButtons {
    display: flex;
    justify-content: space-evenly;

    .button {
      font-size: 13px !important;

      img {
        filter: none !important;
      }
    }

    .stopButton {
      color: #dd0000;
    }

    .enabledButton {
      color: $lightblue;
    }

    .grayedOutButton {
      color: #a8b9c7;
    }
  }

  .lidButtons {
    img {
      height: 40px !important;
      width: 47px !important;
    }
  }

  .actionButtons {
    img {
      height: 25px !important;
      width: 25px !important;
    }
  }

  hr {
    margin: 16px 0 !important;
  }
}