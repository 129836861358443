@import "../../../../../../frontend-common-libs/src/scss/definitions";

.contextBar {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 2px;
    background: $toolbarBackgroundColor;
    box-shadow: 0 1px 2px 0 $boxShadowColor;
    justify-content: space-between;
    padding: 0 22px 0 15px;

    span {
        white-space: pre;
        font-size: 13px;
        color: $breadcrumbTextGrayColor;
    }
}

.contextBarEvenly {
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
        justify-content: space-evenly;
        white-space: pre;
    }
}
