@import '../../../../../frontend-common-libs/src/scss/definitions';

.createReservationsTable {
  @mixin checkbox-col {
    width: 40px;
  }

  @mixin status-col {
    width: 200px;
  }

  th,
  td:nth-of-type(1) {
    @include checkbox-col;
  }

  th,
  td:nth-of-type(4) {
    @include status-col;
  }
}
