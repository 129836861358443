@import '../../../frontend-common-libs/src/scss/definitions';

.header-line {
  font-size: 14px;
  font-weight: 500;
  height: 17px;
  margin-left: 2px;
  margin-bottom: 8px;
}

.tenant-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .tenant-rows {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    border: solid 1px $darkGray;
    border-radius: 7px;
    padding: 0 16px;
    background: $white;

    .row-selected {
      background-color: $tableRowBackgroundColor !important;
    }

    .tenant-row {
      display: flex;
      align-items: center;
      color: $primaryLinkColor;
      font-size: 14px;
      margin: 1px 0 1px 0;
      height: 60px;
      background-color: $white;
      border-bottom: 1px solid $borderGrayColor;

      &:hover {
        background-color: $rowHover;
      }

      &:focus {
        outline: none;
        border-top: 1px solid $focusedElementBorderColor;
        border-bottom: 1px solid $focusedElementBorderColor;
      }

      .tenant-icon {
        margin-right: 9px;
      }

      .tenant-active {
        min-width: 8px;
        max-width: 8px;
        margin: 0 8px 0 14px;
      }
    }
  }
}


