$darkGray: #acacac;
$white: #ffffff;
$headerGray: #6d7687;
$separatorColor: #dcdfe0;
$rubikFontFamily: 'Rubik', sans-serif;
$oceanBlue: #0064b0;

.apiPortalWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: nowrap;
  place-items: baseline;
}

.apiTokensTableWrapper {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 80%;
  margin: 8px 20px 24px 0px;
  padding: 24px 24px 16px;
  border-radius: 7px;
  border: solid 1px $darkGray;
  background-color: $white;
  overflow: auto;

  .deleteButton {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
  place-self: center;

  span {
    margin-right: 10px;
  }
}

.apiTokensTable {
  display: table;
  width: 100%;
  text-align: left;
  font-family: $rubikFontFamily;
  font-weight: normal;

  tr td:first-of-type {
    flex-grow: 1;
    min-width: 400px;
  }

  tr td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
    max-width: 100px;
  }

  thead {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.1px;
    color: $headerGray;

    tr {
      height: 34px;
    }

    td {
      font-weight: bold;
    }
  }

  tbody {
    width: 100%;

    tr {
      height: 35px;
      position: relative;
      border-bottom: 1px solid $separatorColor;
    }

    tr:last-of-type {
      border-bottom: none;
    }
  }
}

.apiPortalHeaderContainer {
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;

  button {
    padding: 5px 14px;
    border-radius: 5px;
    border: solid 1px $oceanBlue;
    color: $oceanBlue;
    font-size: 12px;
    width: 155px;
    height: 28px;
    background-color: transparent;
  }
}
