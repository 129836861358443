@import '../src/frontend-common-libs/src/scss/definitions';

%base-notify {
  border-radius: 4px;
  text-align: center;

  .Toastify__close-button:focus{
    outline: none;
  }
}

.success-notify {
  @extend %base-notify;
  --toastify-text-color-success: #fff;
  --toastify-color-success: #{$primaryColor};
}

.error-notify {
  @extend %base-notify;
  --toastify-text-color-error: #fff;
  --toastify-color-error: #{$primaryLinkColor};
}

.info-notify {
  @extend %base-notify;
  --toastify-text-color-info: #fff;
  --toastify-color-info: #{$newDarkGrey};
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  animation: Toastify__trackProgress linear 1;
}
