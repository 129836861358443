@import '../../../scss/definitions';

.br-checkbox-group {
  margin: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: $rubikFontFamily;
  font-weight: 400;
  font-size: 13px;
  color: $newDarkGrey;

  &:active,
  &:focus {
    outline: none;
  }

  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  .br-hidden-checkbox-input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .br-checkbox {
    position: relative;
    margin: 0 5px 0 0;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px $secondaryGreyColor;
    background-color: $white;

    &:hover {
      cursor: pointer;
    }
  }

  &:focus-within {
    .br-checkbox {
      border: solid 1px $focusedElementBorderColor;
      box-shadow: $insetShadow, $glowShadow;
      outline: none;
    }
  }

  &.checked {
    .br-checkbox {
      background-color: $lightblue;
      border-color: $lightblue;
      &::after {
        content: url(../assets/checkmark-white.svg);
        position: absolute;
        left: 1px;
        top: 0px;
        width: 12px;
        height: 12px;
      }
    }
  }

  &[disabled] {
    color: $disabledColor;
    .br-checkbox {
      border-color: $disabledColor;
      box-shadow: none;
      cursor: not-allowed;
    }
    &.checked .br-checkbox {
      background-color: $disabledColor;
    }
  }
}
