/* CSS Styles for the PCR protocol chart */

$protocolStepBoxBackground: #e6e6e6;

.axis {
  line {
    stroke: $secondaryColor;
  }

  .domain {
    stroke: $secondaryColor;
  }
}

.axis-label {
  fill: $newDarkGrey;
  font-size: 12px;
  text-anchor: middle;
}

.end-bar {
  fill: $protocolEndBarColor;
  shape-rendering: crispEdges;
}

.end-bar-text {
  font-family: $rubikFontFamily;
  text-anchor: middle;
  writing-mode: vertical-rl;
  text-orientation: upright;
  fill: $white;
  text-transform: uppercase;
  font-size: 14px;
}

.graph-box {
  &:hover .step-box-num {
    fill: $protocolStepBoxActiveBackground;
  }
}

.step-box {
  fill: $tableRowDetailsBackgroundColor;
  stroke: $protocolBorderColor;
  shape-rendering: crispEdges;
  pointer-events: auto;
  cursor: pointer;
  fill-opacity: 0.1;

  &.active {
    cursor: default;
    stroke-width: 2px;
    stroke: $activeStepBoxBorderColor;
  }
}

.step-box-num {
  fill: $protocolStepBoxBackground;
  pointer-events: auto;

  &.active {
    fill: $protocolStepBoxActiveBackground;
  }
}

%chart-label {
  font-family: $rubikFontFamily;
  text-anchor: middle;
  font-size: 11px;
  fill: black;
}

.step-num-text {
  @extend %chart-label;
  font-weight: 500;
}

.temperature-path {
  stroke-width: 2;
  stroke: $protocolGraphPrimaryColor;
  stroke-linecap: round;
}

.temperature-label {
  @extend %chart-label;
  font-weight: 500;
  font-size: 14px;
  fill: $newDarkGrey;
}

.time-label {
  @extend %chart-label;
  font-weight: 500;
  font-size: 14px;
  fill: $newDarkGrey;
}

#marker-arrow {
  path {
    fill: $white;
    fill-rule: 'evenodd';
    stroke: $protocolGraphPrimaryColor;
    stroke-width: 2;
    stroke-linecap: round;
  }
}

.goto-label {
  font-size: 14px;
  font-weight: 500;
  fill: $newDarkGrey;
  text-anchor: middle;
  font-family: $rubikFontFamily;
}

.camera-default {
  fill: $newDarkGrey;
}
