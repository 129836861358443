.content-container {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
}

#main-content {
  flex-grow: 1;
  margin-bottom: 17px;
}

#footer-container {
  width: 100%;
  min-height: 140px;
  max-height: 140px;
  background-color: #4c4c4c;
  margin-top: 17px;

  footer {
    padding: 16px 30px;
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li,
      a {
        font-family: $rubikFontFamily;
        color: $white;
        font-size: 17px;
        font-weight: 100;
      }
      li {
        display: inline-block;

        &::after {
          content: '|';
          margin: 0 18px;
          font-size: 13px;
        }

        &:last-child::after {
          content: none;
        }
      }
    }
  }
}
