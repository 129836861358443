@import '../../../../../src/frontend-common-libs/src/scss/definitions';
@import '../../../../frontend-common-libs/src/components/common/dialogs/styles/modal-dialog-v2';

.edit-org-dialog {
  @extend %modal-dialog-style-v2;
  @include modal-dialog-width(594px);

  .modal-header {
    padding: 24px 0 12px 0;
    margin: 0 24px;
  }

  .modal-body {
    padding: 0 24px 0 24px;
  }

  .modal-footer {
    padding: 16px 0 24px 0;
    margin: 0 24px;

    .modal-footer-own {
      #modal-cancel {
        display: none;
      }

      #modal-ok {
        width: 130px;
      }
    }
  }
}

.edit-org-dialog-panel {
  font-size: 13px;
  color: $newDarkGrey;
  display: flex;
  flex-direction: column;
  padding: 16px 0 33px 0;

  .org-name {
    border-radius: 3px;
    height: 30px;
    padding-left: 8px;
    margin-top: 2px;
  }

  .edit-error {
    font-size: 12px;
    font-weight: 400;
    color: $invalidColor;
    margin-top: 4px;
    height: 20px;
  }

  .org-description {
    overflow: auto;
    resize: none;
    white-space: pre-wrap;
    height: 86px;
    padding: 9px 8px;
    margin-top: 2px;
    border-radius: 3px;
    border: solid 1px $inputBorderColor;
  }
}