@import '../../../../frontend-common-libs/src/scss/definitions';

$fileRowBorderWidth: 1px;
$fileRowBorderType: solid;
$fileRowBorderWidthType: $fileRowBorderWidth $fileRowBorderType;

.fileRow {
  display: flex;
  padding: 0 14px;
  flex-grow: 1;
  height: 68px;
  position: relative;
  margin-bottom: 4px;
  margin-top: 4px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: $fileRowBorderWidthType $separatorColor;
    bottom: -5px;
  }

  &:first-child {
    margin-top: 3px;
  }

  td {
    @include ellipsis-text;
    align-self: center;
  }

  &:last-child {
    margin-bottom: 3px;

    &:after {
      content: '';
      border-bottom: none;
    }
  }
}

.fileIconCol {
  width: 30px;
  height: 35px;
}

.fileNameCol {
  width: 281px;
  margin-right: 15px;
  color: $primaryLinkColor;
  flex-grow: 1;
  padding: 6px 0 0 8px;
}

.fileNameCol,
.fileTypeCol,
.fileLastUpdatedCol {
  min-width: 200px;
  font-family: $rubikFontFamily;
  font-size: 14px;
}

.fileTypeCol {
  margin-left: 10px;
  width: 110px;
  padding-top: 6px;
}
.fileLastUpdatedCol {
  margin-left: 10px;
  width: 185px;
  margin-right: 10px;
  padding-top: 6px;
}