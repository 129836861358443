.buttonmenu-item.buttonmenu-header {
  cursor: default;
  padding: 14px 14px 14px 14px;

  span {
    font-weight: 500;
  }
  &:hover a {
    text-decoration: none;
    cursor: default;
  }
}
