b {
  font-weight: 400;
  color: #00a650;
}

#eap-info {
  font-size: 20px;
  margin-top: 20px;
}

#eap-submission-message {
  font-size: 20px;
  margin-top: 25px;
  background-color: inherit;
  margin-left: 50px;
  margin-right: 50px;
  text-align: left;
}


