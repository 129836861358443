@import '../../../../src/frontend-common-libs/src/scss/definitions';

.org-people {
  max-width: 805px;
  background-color: transparent;
  margin-top: 16px;

  .members-table {
    display: flex;
    flex-direction: column;
    margin: 0 0 50px 0;
    background: $toolbarBackgroundColor;
    border-radius: 3px;
    border: solid 1px $borderGrayColor;

    .members-header {
      display: flex;
      align-items: center;
      height: 35px;
      padding: 0;
      background-color: $toolbarBackgroundColor;
      color: $secondaryGreyColor;
      font-size: 11px;
      border-radius: 3px 3px 0 0;
      border-bottom: solid 1px $borderGrayColor;

      .name-header {
        padding-left: 24px;
      }

      .role-header {
        margin-left: auto;
        width: 205px;
      }
    }

    .member-rows {
      display: flex;
      flex-direction: column;
      background: $white;

      .member-row {
        display: flex;
        color: $secondaryGreyColor;
        font-size: 12px;
        height: 57px;
        padding: 0 60px 0 24px;
        border-bottom: 1px solid $separatorColor;

        &:hover {
          background-color: $rowHover;
        }

        &:last-child {
          border-bottom: none;
          border-radius: 0 0 3px 3px;
        }

        .br-select {
          min-width: 150px;
        }

        .br-select-button {
          background: $white;
          border: 1px solid $borderGrayColor;
          height: 30px;
        }

        .br-select-display {
          color: $newDarkGrey;
        }

        .br-select-menu-item {
          font-weight: normal;
          font-size: 12px;
        }

        .remove-user {
          margin-right: -37px;
        }
      }
    }

  }

  .add-user-to-tenant {
    margin: 0;

    .add-member-header {
      color: $secondaryGreyColor;
      font-size: 11px;
      border-bottom: solid 1px $separatorColor;
    }

    .columns-row {
      .column-header {
        color: $secondaryGreyColor;
      }

      .column-header-role {
        width: 210px;
      }
    }

    .invited-user-row {
      .invited-user {
        display: flex;

        .br-select {
          width: 150px;
          min-width: 150px;
        }
      }
    }
  }
}