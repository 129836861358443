#create-experiment-component {
  display: flex;
  height: 100%;
  flex-grow: 1;
  justify-content: flex-end;
  font-family: $rubikFontFamily;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  background-color: $secondaryColor;

  #create-experiment-main {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-evenly;
  }

  #define-experiment-inline {
    display: inline-flex;
    align-items: center;
    margin-top: 5px;
    a {
      font-size: 16px;
    }
  }

  #define-experiment-aligned-top {
    display: inline-flex;
    align-items: flex-start;
    margin-top: 5px;

    a {
      font-size: 16px;
    }

    #create-run-links-column {
      display: flex;
      flex-direction: column;

      .real-time-pcr-header {
        margin-top: 4px;
        font-size: 16px;
      }

      > * {
        margin-bottom: 10px;
      }
    }
  }

  .create-experiment-column {
    flex-direction: column;
    margin-top: 70px;
  }

  #modify-components-text {
    font-size: 14px;
  }

  h1{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: $headerGreyColor;
  }

  h2{
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: $headerGreyColor;
  }

  a{
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    color: $primaryLinkColor;
    &:hover{
      color: $primaryHoverLinkColor;
    }
  }

  .pcr-curve-icon {
    margin-right: 15px;
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

.create-experiment-page {
  display: flex;
  flex-direction: column;
  margin: 20px 42px;

  .create-experiment-header {
    font-size: 16px;
    font-weight: 500;
    color: $brGreen;
    margin-bottom: 20px;
  }
}
