@import '../../../../../../scss/definitions';

.gradient-calculator-container {
  margin: 20px 9px 9px;
}

#gradient-calculator-header {
  font-size: $primaryFontSize;
  font-weight: 500;
}
