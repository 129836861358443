@import '../../../../../frontend-common-libs/src/scss/definitions';

td {
  .cellText {
    color: $newDarkGrey;
  }

  .commonColumn {
    display: inline-flex;
    height: 100%;
    margin-top: 12px;
  }

  .statusImg {
    width: 16px;
    height: 16px;
    background: inherit;
    margin-right: 6px;
  }
}