@import "../../../../frontend-common-libs/src/scss/definitions";

.registerUser {
  overflow: auto;
  height: 100%;
}

.registerButton {
  width: 100%;
  height: 31px;
  font-size: 14px;
  margin: 24px 0 16px 0;
}

.termsOfUseBox{
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 13px;
}

.termsOfUse {
  margin-left: 4px;
  color: $primaryLinkColor !important;
}