#page-not-found, #message, #complete-registration-error {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .content {
    display: flex;
    align-items: center;

    img {
      width: 150px;
      margin-right: 20px;
    }

    .description {
      width: 396px;
      h1 {
        text-transform: uppercase;
        font-size: 34px;
        font-weight: 400;
        margin: 0;
      }

      p {
        font-size: 24px;
        margin: 0;
        line-height: 1.25;
      }
    }
  }
}

// when logged in
.right-panel #page-not-found {
  background-color: $white;
}

@media (max-width: 665px) {
  #page-not-found .content {
    flex-direction: column;
    img {
      margin-right: 0;
      margin-bottom: 20px;
    }

    .description {
      text-align: center;
    }
  }
}
