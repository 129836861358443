@import '../../../frontend-common-libs/src/scss/definitions';

.auto-suggest-container {
    position: relative
}

.aria-autosuggest-dropdown {
    position: absolute;
    z-index: 1000;
    max-height: 300px;
    width: 100%;
    min-width: 160px;
    margin-top: 4px;
    padding: 7px 14px;
    border-radius: 3px;
    text-align: left;
    background-color: $white;
    box-shadow: 0 0 6px 2px $shadowColor;
    overflow-x: hidden;
    overflow-y: auto;

    &.hide {
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
    }

    &:focus {
        outline: none;
    }

}

.aria-autosuggest-dropdown-item {
    cursor: pointer;
    padding: 7px 0;
    font-size: 14px;
    color: $newDarkGrey;
    font-weight: 400;
    border-bottom: solid 1px transparent;
    list-style: none;

    &.highlighted {
        color: $lightblue;
        background: inherit;
    }

    &:focus,
    &:hover {
        outline: none;
        color: $lightblue;
        background-color: inherit;
    }

}
