.plate-size-selection {
  margin-top: 6px;

  .br-radio-label {
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    font-weight: normal;
    margin-right: 16px;
    margin-bottom: 0px;

    &:last-child {
      margin-right: 0;
    }
  }
}