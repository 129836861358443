$gray: #808080;
$bold: 500;

.toolbar-popover {
  position: fixed;
  width: 593px;
  padding: 12px;
  border: 1px solid $gray;
  background-color: #f3f1f1;
  top: 125px;
  right: 8px;
  display: flex;
  flex-direction: column;
  font-family: $rubikFontFamily;
  color: $newDarkGrey;
  font-size: 13px;

  .popover-navbar {
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    font-size: $primaryFontSize;
    font-weight: $bold;
    border-bottom: 1px solid $gray;
  }

  .popover-navigation-button {
    @extend %no-border-button;
    @extend %no-focus-outline-on-click;
  }

  .popover-header {
    padding-bottom: 8px;
    font-size: $primaryFontSize;
    font-weight: $bold;
    border-bottom: 1px solid $gray;
  }

  .popover-content {
    padding: 0;
    .popover-section {
      padding-bottom: 10px;
      border-bottom: 1px solid #c6c6c6;
      margin-bottom: 10px;

      &:last-of-type {
        border-bottom: none;
        margin-bottom: 1px;
      }

      .section-header,
      .sub-section,
      .sub-section .popover-label {
        margin-bottom: 12px;
      }

      .sub-section .popover-label:last-of-type {
        margin-bottom: 0px;
      }

      .sub-section {
        margin-left: 55px;
        .popover-label .popover-value {
          font-weight: $bold;
          font-size: $primaryFontSize;
          margin-left: 6px;
        }
        .popover-link {
          margin-left: 110px;
          color: $lightblue;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      #baseline-checkbox {
        flex-grow: 1;
        align-items: start;
      }

      & :last-child {
        margin-bottom: 0px;
      }
    }
  }

  .popover-panel {
    display: flex;
    flex-grow: 1;
    padding-top: 10px;

    .popover-left-panel {
      width: 150px;
      border-right: 1px solid $gray;
      text-align: right;
      padding-right: 10px;
      font-weight: $bold;
      font-size: $primaryFontSize;
    }

    .popover-right-panel {
      flex-grow: 1;
      padding-left: 12px;
      display: flex;
      flex-direction: column;
    }

    .popover-footer {
      display: flex;
      justify-content: flex-end;

      button.utility {
        min-width: 54px;
      }

      .threshold-close-button {
        margin-left: 12px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    width: unset;
    left: 61px;
    overflow: auto;
  }

  @media screen and (max-height: 370px) {
    bottom: 10px;
    overflow: auto;
    height: unset;
  }
}

.threshold-panel {
  width: 100%;

  &-toolbar {
    display: flex;
    justify-content: space-between;
    height: 20px;
    margin-bottom: 10px;
  }
}

.br-data-table {
  height: 40px;
  margin-bottom: 10px;
  th,
  td {
    &.target-column {
      width: 120px;
      @include ellipsis-text;
      padding-right: 3px;
    }
    &.auto-threshold-column {
      width: 100px;
    }
    &.user-threshold-column {
      width: 130px;
    }
  }

  .br-text-label {
    width: 90px;
    height: 20px;
    border: 1px solid grey;
    box-shadow: none;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 400;
    border-radius: 0px;
    font-size: 12px;
  }

  .br-text-label:disabled {
    background-color: #eeeeee;
    color: #b1b3c0;
  }

  .br-radio-label {
    display: flex;
    align-items: center;
    margin: 0;
    font-weight: 400;

    &:focus {
      outline: none;
    }
  }

  input[type='radio'] {
    width: 15px;
    height: 15px;

    &:checked::before {
      width: 7px;
      height: 7px;
      transform: translate(-55%, -55%);
    }
  }
}

.cycle-range-panel {
  max-height: 370px;

  .select-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    .br-select {
      margin-left: 10px;
      width: 190px;

      .br-select-menu {
        max-height: 220px;
      }
    }
  }

  .well-cycle-range-table {
    background-color: #f3f1f1;
    max-height: 253px;
    width: 390px;
    overflow-y: auto;
    margin-top: 5px;
    padding-top: 0px;
    border-width: 0px;
    display: block;

    .well-col {
      background: #f3f1f1;
      padding-left: 10px
    }

    .well-header {
      background: #f3f1f1;
      padding-top: 5px;
      max-width: 70px;
      width: 70px;
      padding-left: 10px
    }

    .select-all-wells-header {
      z-index: 1;
      position: sticky;
      vertical-align: middle;
    }
  }

  .int-input {
    width: 60px;
  }

  .edit-selected-wells {
    border-right: 0px;
    border-left: 0px;
    padding-top: 3px;
    padding-bottom: 5px;
    margin-left: 10px;
    height: auto;
    width: fit-content;
    background: transparent;
    font-size: 13px;

    .header-selected-wells {
      width: 204px;
      padding-top: 2px;
    }

    .no-table_scroll {
      width: 218px;
    }

    .header-begin-end {
      width: 70px;
      padding-left: 5px;
      color: $headerGreyColor;
    }

    .center-button {
      padding-left: 25px;
      padding-top: 4px;
    }
  }
}