@import "../../../../scss/definitions";

.uploadButton {
    border: none;
    background: none;
    font-family: $rubikFontFamily;

    span {
        margin-left: 5px;
        font-size: 12px;
        color: $lightblue;
    }

    &:active, &:focus {
        outline: 1px solid $focusedElementBorderColor;
    }

    &:disabled {
        span {
            color: $disabledColor;
        }
    }
}
