$focus-border-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
0 0 8px rgba(2, 91, 42, 0.37) !important;
$entry-border: solid 1px #333;
$border-color: #333 !important;
$error-border-color: $invalidColor !important;

@mixin base-br-select-display {
  height: 40px;
  border-radius: 2px;
}

@mixin base-rbt-input-hint-container {
  .rbt-input-hint-container {
    display: flex;

    &:after {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      border: solid 1px #C2C2C2;
      content: url(../src/frontend-common-libs/src/img/icons-select-down-arrow.svg);
      &:focus,
      &:hover {
        content: url(../src/frontend-common-libs/src/img/icons-select-down-arrow-active.svg);
      }
    }
  }
}

@mixin base-dropdown {
  .dropdown-menu {
    max-height: 400px !important;
  }
}

.country-form {
  @include base-rbt-input-hint-container();
  @include base-dropdown();

  .br-select-display {
    @include base-br-select-display();
  }

  &:focus {
    outline: none;
    box-shadow: $focus-border-shadow;
    -webkit-box-shadow: $focus-border-shadow;
  }
}

.country-form-active{
  @include base-rbt-input-hint-container();
  @include base-dropdown();

  input.form-control {
    &:focus {
      border: $entry-border;
    }
  }

  .rbt-input-hint-container {
    &:after {
      border-top-color: $border-color;
      border-right-color: $border-color;
      border-bottom-color: $border-color;
      border-left-color: transparent;
      outline: none;
      box-shadow: $focus-border-shadow;
      -webkit-box-shadow: $focus-border-shadow;
    }
  }

  .br-select-display {
    @include base-br-select-display();
    border: $entry-border;
    outline: none;
    box-shadow: $focus-border-shadow;
    -webkit-box-shadow: $focus-border-shadow;
  }
}

@mixin base-country-form-error {
  input.form-control {
    &:focus {
      border: solid 1px $invalidColor;
      box-shadow: $focus-border-shadow;
    }
  }

  .rbt-input-hint-container {
    &:after {
      border-top-color: $error-border-color;
      border-right-color: $error-border-color;
      border-bottom-color: $error-border-color;
      border-left-color: transparent;
      outline: none;
    }
  }

  .br-select-display {
    @include base-br-select-display();
    border-color: $error-border-color;
    outline: none;
  }

  &:focus {
    outline: none;
    box-shadow: $focus-border-shadow;
  }
}

.country-form-error{
  @include base-rbt-input-hint-container();
  @include base-country-form-error();
  @include base-dropdown();
}

.country-form-error-active {
  @include base-rbt-input-hint-container();
  @include base-country-form-error();
  @include base-dropdown();

  .rbt-input-hint-container {
    &:after {
      box-shadow: $focus-border-shadow;
    }
  }
}
