@import '../../../../frontend-common-libs/src/scss/definitions';

.noReservationMessage {
  padding: 111px 0 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: $darkGrey;

  p {
    margin: 0;
  }

  strong {
    font-weight: 600;
  }
}