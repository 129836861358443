@import '../../../../../frontend-common-libs/src/scss/definitions';

.fleetReservationModal {
  background-color: rgba(103, 117, 126, 0.55);

  :global {
    .modal-dialog {
      width: 350px;
      height: 183px;
      top: 35%;
    }

    .btn {
      width: 47px;
      height: 31px;
      border-radius: 6px;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.65), inset 0 2px 0 0 rgba(255, 255, 255, 0.45);
      border: solid 1px $primaryBorderColor;
      background-color: $primaryBorderColor !important;
      color: #fff !important;
      font-size: 14px;
    }
  }
}

.fleetReservationModalBody {
  font-weight: 500;
  text-align: center;
  padding-top: 40px;

  .fleetReservationModalBodyText {
    padding-top: 10px;
  }
}

.fleetReservationModalFooter {
  border: none;
  padding-top: 0;
}