$orange: #ec4e1b;
$darkOrange: #bc3409;

#header-container {
  height: 77px;
  background-color: $white;

  header {
    display: flex;
    align-items: center;
    height: 77px;
    width: 100%;
    padding-left: 32px;
    padding-right: 24px;
  }

  nav {
    flex-grow: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #br-logo img {
    width: 99px;
    height: 26px;
  }

  #login-btn {
    height: 18px;
    font-family: $rubikFontFamily;
    font-size: 18px;
    font-weight: 400;
    color: #ff6600;
    line-height: 1.11;
    margin-right: 30px;
    background-color: $white;
    border: none;

    img {
      margin-left: 7px;
    }

    &:focus:hover {
      box-shadow: none;
    }
  }

  #signup-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    height: 39px;
    background: $orange;
    font-family: $rubikFontFamily;
    font-size: 18px;
    font-weight: 400;
    color: $white;
    border-radius: 0;
    border: none;
    box-shadow: none;

    &:hover {
      background: $darkOrange;
      border-color: $darkOrange;
      color: $white;
    }
    &:focus:hover {
      box-shadow: none;
    }
  }
}

.unauth-header-menu-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  list-style-type: none;

  .navbar-right {
    margin-right: 15px;
  }
}

#main-header {
  box-shadow: 0 5px 5px $brGreen;
  margin-bottom: 4px;
  font-family: $rubikFontFamily;

  #navbar-static-top {
    display: flex;
    height: 73px;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    padding-left: 15px;
  }

  #header-title {
    display: inline-flex;
    align-items: center;

    h1 {
      font-size: 18px;
      color: $brGreen;
      margin: 1px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #bio-rad-title {
      font-weight: normal;
      margin-left: 20px;
    }
  }

  img {
    object-fit: contain;
  }

  ul {
    display: flex;
    margin-bottom: 0;
    margin-top: 0;
  }

  li {
    display: inline-flex;
    margin-left: 16px;
    cursor: pointer;
  }

  .upload-button {
    width: 30px;
    height: 30px;
    @extend %no-border-button;
    @extend %no-focus-outline-on-click;
  }
}

.nav .open > a {
  background-color: inherit;
  border-color: inherit;
  &:focus,
  &:hover {
    background-color: inherit;
    border-color: inherit;
  }
}

a.nav-button {
  &:link,
  &:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }
}

#help-buttonmenu,
#profile-buttonmenu.buttonmenu {
  #buttonmenu-menu {
    right: 0;
    top: 35px;
  }

  .notification-icon-profile-menu {
    top: -7px;
    left: -2px;
  }

  .notification-icon-menu-item {
    top: -2px;
    left: 4px;
  }
}

#scheduled-maintenance-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: yellow;
  font-weight: bold;
  place-self: center;
  width: 70%;
  height: 90%;
  margin: 5px 10px;

  #markdown-content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    text-align: justify;
    font-size: 0.8vw;
    padding: 5px 10px;
    align-self: center;
  }

  a {
    display: contents;
  }

  .btn {
    border: none;
    color: red;
    background-color: transparent;
    font-size: 1.2vw;
  }
}
