@import "../../../../../../frontend-common-libs/src/scss/definitions";

.breadcrumbs {
    display: flex;
    min-width: 0;

    .breadcrumbSeparator {
        margin: 0 3px;
    }

    .breadcrumbNotLast {
        max-width: 200px;
        min-width: 10px;
    }

    .breadcrumbLast {
        min-width: 50px;
    }
}
