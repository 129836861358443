@import '../../../../../frontend-common-libs/src/scss/definitions';

.manageReservationsTable {
  @mixin checkbox-col {
    width: 40px;
  }

  @mixin status-col {
    width: 200px;
  }

  @mixin protocol-col {
    width: 338px;
    margin-right: 24px;

    @media only screen and (max-width: $minSupportedWidth) {
      width: 200px;
    }
  }

  th,
  td:nth-of-type(1) {
    @include checkbox-col;
  }

  th,
  td:nth-of-type(4) {
    @include status-col;
  }

  th,
  td:nth-of-type(5) {
    @include protocol-col;
  }
}
