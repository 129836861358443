@import "../../../../frontend-common-libs/src/scss/definitions";

.forgotPasswordContainer {
  line-height: 1.29;
  width: 100%;
  display: flex;
  justify-content: center;
}

a.forgotPassword {
  color: $primaryLinkColor;
  font-weight: 600;
}

@mixin loginPageButton {
  width: 100%;
  height: 31px;
  font-size: 14px;
}

.loginButton {
  margin: 30px 0 16px 0;
  @include loginPageButton
}

.ssoLoginButton {
  margin: 0 0 16px 0;
  @include loginPageButton
}