@import '../../../../src/frontend-common-libs/src/scss/definitions';

.add-user-to-tenant {
  display: flex;
  flex-direction: column;
  margin: 24px 16px 16px 16px;

  .invited-user-row {
    .error-message {
      display: inline-block;
      font-size: 12px;
      font-weight: 400;
      color: $invalidColor;
      margin-top: 4px;
    }

    .invited-user {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .invited-user-email {
        padding-left: 8px;
        flex-grow: 1;
        min-width: 0;

        ::placeholder {
          color: $placeholderTextColor;
          font-size: 12px;
          padding-left: 8px;
        }
      }

      .br-select {
        align-self: center;
        width: 112px;
        min-width: 112px;
        margin: 0 8px;

        .br-select-button {
          height: 30px;
        }

        .br-select-menu-item {
          font-weight: normal;
          font-size: 12px;
        }
      }

      .invite-user-button {
        min-width: 54px
      }

    }
  }

  .add-member-header {
    color: $newDarkGrey;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 6px;
    border-bottom: solid 1px $headerGreyColor;
  }

  .columns-row {
    display: flex;
    margin: 8px 0 3px 0;

    .column-header {
      font-size: 11px;
      color: $newDarkGrey;
    }

    .column-header-role {
      margin-left: auto;
      width: 172px;
    }
  }

}
