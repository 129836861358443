.run-details-wrapper-rows {
  flex: 1 0 1px;
  padding: 22px 15px 15px 16px;
  margin: 0 14px 14px 12px;
  color: $newDarkGrey;
  background: white;
  overflow: auto;
  border-radius: 0 0 3px 3px;
  border: 1px solid $newBorderGrayColor;
  border-top: 0;
  display: flex;
  flex-direction: column;

  .run-details-wrapper-columns {
    display: flex;
    flex-direction: row;
    flex: auto;

    .run-details-content {
      font-family: $rubikFontFamily;
      width: 360px;
      min-width: 360px;
    }

    .vertical-separator {
      border-left: 1px solid $tableRowDetailsTitleColor;
      margin: 0px 50px 0px 50px;
    }

    .run-completed-content {
      font-family: $rubikFontFamily;
      min-width: 200px;
      display: flex;
      flex-direction: column;
    }

    .instrument-details-content {
      font-family: $rubikFontFamily;
      min-width: 180px;
      display: flex;
      flex-direction: column;
      padding: 0px 0px 0px 50px;
    }

    .run-log-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .log-panel-header-row {
        display: flex;
        flex-direction: row;

        .run-log-label {
          flex-grow: 1;
        }
      }

      .hide-log-button {
        padding-right: 14px;
      }

      .log-panel-body {
        display: flex;
        flex-grow: 1;

        .loading-log {
          text-align: center;
          align-self: center;
          flex-grow: 1;
        }

        .log-table {
          border: 0px;
          max-height: calc(100vh - 260px);
          overflow-y: auto;
          margin-top: 12px;
          margin-bottom: 0px;
          padding-top: 0px;

          .date-col {
            vertical-align: top;
            width: 130px;
            min-width: 130px;

          }

          .message-col {
            vertical-align: top;
            min-width: 150px;
            overflow-wrap: anywhere;
          }

          .severity-col {
            vertical-align: top;
            width: 80px;
            min-width: 80px;
            padding-left: 5px;
          }

          .log-col {
            vertical-align: top;
            width: 120px;
            min-width: 120px;
          }

          .level-col {
            vertical-align: top;
            width: 80px;
            min-width: 80px;
            padding-left: 5px;
          }
        }
      }
    }

    .show-log-button {
      align-self: flex-start;
    }
  }

  .run-details-footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
