@import 'definitions';

%input-textarea {
  width: 100%;
  border: 1px solid $inputBorderColor;
  color: $newDarkGrey;
  border-radius: 3px;
  height: 30px;
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  padding-left: 8px;
  font-size: 11px;

  &:focus {
    border: solid 1px $focusedElementBorderColor;
    box-shadow: $insetShadow, $glowShadow;
    outline: none;
  }

  &::placeholder {
    color: $secondaryGreyColor;
    font-family: $rubikFontFamily;
  }

  @include ellipsis-text;
}
