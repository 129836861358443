@import '../../../scss/definitions';

.create-experiment-card {
  display: flex;
  flex-direction: column;
  width: 606px;
  height: 120px;
  margin-bottom: 30px;
  border-radius: 3px;
  box-shadow: 0 0 7px 2px $newShadowColor;
  background-color: $white;

  &:hover {
    box-shadow: 0 0 7px 2px $lightGray;
  }

  a {
    &:link, &:visited, &:hover {
      color: $primaryLinkColor;
    }
    @extend %no-focus-outline-on-click;
  }

  .disabled-link {
    color: $disabledColor;
    cursor: not-allowed;
  }

  .card-header {
    margin: 14px 0 8px 14px;
    font-size: 16px;
    color: $headerGreyColor;
  }

  .card-columns {
    display: flex;
    flex-direction: row;

    .links-column {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: space-evenly;
    }

    .image-col {
      width: 110px;
      padding-left: 14px;
    }

    .run-col {
      width: 265px;
    }

    .protocol-col {
      margin-left: 30px;
    }

    .v-sep {
      width: 1px;
      height: 43px;
      align-self: center;
      background-color: $newMediumGrey;
    }
  }
}