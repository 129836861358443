@import 'src/frontend-common-libs/src/scss/definitions';

.instrument-name-and-model {
  margin-top: 7px;
  display: flex;
  flex-direction: row;

  .instrument-labels {
    margin-left: 10px;
  }
}

