@import '../src/frontend-common-libs/src/scss/definitions';

label[disabled] {
  color: $disabledColor;
  cursor: not-allowed;
}

input, 
/* override bs */
input.form-control {
  border: solid 1px $inputBorderColor;
  box-shadow: $insetShadow;

  &:focus {
    border: solid 1px $focusedElementBorderColor;
    box-shadow: $insetShadow, $glowShadow;
    outline: none;
  }
}

input[type='radio'],
/* override bs */
input[type='radio'].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background-color: transparent;
  border: 1px solid $secondaryGreyColor;
  box-shadow: none;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-right: 6px;
  margin-top: 0px;
  outline: none;

  &:checked {
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      background-color: $lightblue;
      width: 10px;
      height: 10px;
      border-radius: 50px;
    }
    &:disabled:before {
      background-color: $disabledColor;
    }
  }

  &:focus {
    box-shadow: $glowShadow;
    /* override bs */
    outline: none;
    outline-offset: none;
  }

  &:disabled {
    border-color: $disabledColor;
  }
}

select,
/* override bs */
select.form-control,
button.dropdown-toggle.btn-default:not(.new-btn) {
  &:focus {
    border: solid 1px $focusedElementBorderColor;
    box-shadow: $glowShadow;
    outline: none;
    background: #fff;
  }
}

.form-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  text-align: left;
  color: #888;
  margin: 24px 0 4px 0;
  text-indent: 2px;
}

.form-label-flex {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  text-align: left;
  color: #888;
  margin: 24px 0 4px 0;
  text-indent: 2px;
  display: flex;
  justify-content: space-between;
}

.form-label-error {
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  color: $invalidColor;
  margin: 24px 0 4px 0;
}

.form-label-error-flex {
  font-size: 12px;
  font-weight: 600;
  text-align: right;
  color: $invalidColor;
}

.form-container {
  width: 392px;
  height: auto;
  margin: 0 auto 0 auto;
}

.form-field {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
  border: solid 1px #888;
  margin: 4px 0 4px 0;
  padding: 0 8px 0 8px;

  &:disabled {
    background-color: $disabledColor;
  }
}

.form-field-invalid {
  border-color: $invalidColor;
}

.form-field:focus {
  border-color: #333 !important;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(2, 91, 42, 0.37) !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(2, 91, 42, 0.37) !important;
}

.form-field-invalid:focus {
  border-color: $invalidColor !important;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(2, 91, 42, 0.37) !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(2, 91, 42, 0.37) !important;
}

.group-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .label-container,
  .control-container {
    flex-basis: 100%;
  }
}

.form-control-group {
  .form-control {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: $newDarkGrey;
    font-size: 11px;
    height: 30px;
    border-radius: 3px;
    background-color: $white;
    border: solid 1px $inputBorderColor;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    &:focus {
      border-color: $focusedElementBorderColor;
    }
  }
  &.has-error {
    .form-control {
      border: solid 1px $invalidColor;
    }
    .error-block {
      color: $invalidColor;
      margin-top: 3px;
      display: block;
    }
  }
  &.title-field {
    .form-control {
      font-size: 14px;
    }
  }
  .dropdown-toggle {
    width: 100%;
    height: 36px;
    font-weight: normal;
    color: #161616;
    font-size: 11px;
    text-align: left;
    border-radius: 2px;
    background-color: $white;
    border: solid 1px $mediumGrey;
  }
  .dropdown-menu {
    font-size: 12px;
    li {
      a {
        padding: 8px 12px;
      }
    }
  }
}

.radio-button-toggle:focus-within {
  outline: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  > label:last-child {
    border-top-color: $primaryColor;
    border-right-color: $primaryColor;
    border-bottom-color: $primaryColor;
  }
  > label:first-child {
    border-top-color: $primaryColor;
    border-left-color: $primaryColor;
    border-bottom-color: $primaryColor;
  }
}

textarea.form-field {
  height: 200px;
  resize: none;
}

.br-toggle {
  // Hidding radio component but preserving it's
  // tab and arrows keys capabilities
  input[type='radio'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    width: 0;
    margin: 0;
  }

  .br-radio-label {
    font-family: $rubikFontFamily;
    color: $lightblue;
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
    margin-bottom: 0;
    margin-right: 17px;

    &::after {
      position: relative;
      right: -8px;
      border-right: 1px solid $separatorColor;
      content: '';
    }

    &:last-child {
      margin-right: 0;
      border-right: none;
      &::after {
        position: relative;
        right: 0;
        border-right: none;
        content: '';
      }
    }

    &.radio-checked {
      font-weight: 500;
      color: $newDarkGrey;
      cursor: default;
      letter-spacing: -0.3px;

      &:hover,
      &:focus,
      &:focus-within {
        text-decoration: none;
      }
    }

    &:focus-within,
    &:active {
      box-shadow: none;
      outline: 1px solid rgba(255, 102, 0, 0.7);
      outline-offset: 2px;
    }

    &:hover,
    &:focus-within {
      text-decoration: underline;
    }
  }
}
