
.upload-status-container {
  bottom: 0;
  display: block;
  left: auto;
  max-height: 350px;
  max-width: 300px;
  overflow: visible;
  position: absolute;
  right: 36px;
  z-index: 1001;

  > .panel-default {
    margin-bottom: 0;

    > .panel-heading {
      background-color: $primaryColor;
      color: white;
      width: 300px;
    }
  }

  .list-group {
    padding-left: 0;
    margin-bottom: 14px;
  }

  .status-header {
    display: inline-block;
    max-width: 200px;
    vertical-align: middle;
  }
}
