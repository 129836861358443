.table-breadcrumb {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 2px;
  background: $toolbarBackgroundColor;
  box-shadow: 0 1px 2px 0 $boxShadowColor;
  justify-content: space-between;
  padding: 0 22px 0 15px;

  span {
      white-space: pre;
      font-size: 13px;
      color: $breadcrumbTextGrayColor;
  }
}

.create-button {
  @extend %no-border-button;
  @extend %no-focus-outline-on-click;
  display: inline-flex;

  .create-text {
    color: $lightblue;
    font-size: 12px;
    height: 16px;
    margin-left: 5px;
  }
}

.protocols-buttons {
  display: inline-flex;
  right: 0;
  left: auto;
  margin-left: 15px;
}
