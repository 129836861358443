/* CSS relating to the file list's file items */

.flex-column-container#archive-table-container,
.flex-column-container#protocols-table-container {
  background-color: $secondaryColor;
  max-height: calc(100vh - 119px);
}

.flex-column-container#files-table-container {
  background-color: $secondaryColor;
  max-height: calc(100vh - 167px);
}

.base-table {
  text-align: left;
  overflow: hidden;
  font-family: $rubikFontFamily;
  font-weight: normal;
  margin-left: 10px;
  margin-right: 10px;

  th,
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
  }

  td:first-of-type,
  th:first-of-type {
    margin-left: 15px;
  }

  td:last-of-type,
  th:last-of-type {
    margin-right: 15px;
  }

  tr {
    display: flex;
    justify-content: flex-start;
    background-color: $secondaryColor;
  }

  thead {
    display: block;
    margin-right: 0px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.1px;
    color: $headerGreyColor;
    border-bottom: 1px solid $headerGreyColor;
    margin-top: 7px;

    tr {
      height: 34px;
    }

    td {
      font-weight: bold;
    }
  }

  tbody {
    display: block;
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;

    tr {
      height: 62px;
      position: relative;
      margin-top: 4px;
      margin-bottom: 9px; /* 4px + 1 for divider + 4 for the next row */

      &:not(.details-row) {
        &:hover {
          background-color: $tableRowBackgroundColor;
          cursor: pointer;
        }

        &:focus-within {
          background-color: $tableRowBackgroundColor;
          a {
            outline: none;
          }
        }

        &:focus {
          outline: 1px solid $focusedElementBorderColor;
          background-color: $tableRowBackgroundColor;
        }
      }

      &:not(.expanded-row) {
        &::after {
          content: '';
          border-bottom: 1px solid $separatorColor;
          position: absolute;
          left: 0px;
          right: 0px;
          bottom: -5px;
          pointer-events: none;
        }
      }
    }

    tr:last-of-type::after {
      border-bottom: none;
    }
  }

  .spinner-row {
    justify-content: center;
  }

  .error-row {
    justify-content: center;
    display: inline-flex;
  }

  .button-link {
    background-color: transparent;
    border: none;
    color: $primaryLinkColor;
    margin: 0px 0px 10px 10px;
    padding: 0px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.base-table#archive-table,
.base-table#files-table,
.base-table#protocols-table {
  tbody {
    tr {
      &:hover {
        .popup-menu-button-img {
          visibility: visible;
        }
      }

      &:focus-within {
        .popup-menu-button-img {
          visibility: visible;
        }
      }

      &:focus {
        .name-cell {
          text-decoration: underline;
        }
      }

      a{
        &:hover {
          .name-link {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @mixin icon-col {
    min-width: 30px;
  }

  @mixin name-col {
    flex-grow: 1;
    min-width: 200px;
    padding: 0 8px;
  }

  @mixin last-updated-col {
    width: 280px;
    /*  hide last updated */
    @media only screen and (max-width: $tabletPortraitMaxWidth) {
      display: none;
    }
  }

  @mixin type-col {
    width: 192px;
    /*  hide type */
    @media only screen and (max-width: $mobilePortraitMaxWidth) {
      display: none;
    }
  }

  @mixin options-col {
    width: 17px;
    text-align: center;
    overflow: visible;
    position: relative;
  }

  th,
  td:nth-of-type(1) {
    @include icon-col;
  }

  th,
  td:nth-of-type(2) {
    @include name-col;
  }

  th,
  td:nth-of-type(3) {
    @include last-updated-col;
  }

  th,
  td:nth-of-type(4) {
    @include type-col;
  }

  th,
  td:nth-of-type(5) {
    @include options-col;
  }

  .name-cell {
    width: 281px;
    height: 18px;
    font-size: 14px;
    line-height: 1.29;
    color: $primaryLinkColor;
  }

  .name-link {
    color: $primaryLinkColor;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon-cell {
    width: 28px;
    height: 28px;
    object-fit: contain;
  }
}
