@import '../../../scss/definitions';

.br-select {
  width: 100%;
  background-color: $white;
  position: relative;
  border-radius: 3px;
  // override default focus outline
  &:focus {
    outline: none;
  }

  // .rbt-* : react-bootstrap-typeahead
  .br-select-button,
  .rbt-input-hint-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 30px;
    padding-right: 26px;
    border: 1px solid $inputBorderColor;
    border-radius: 3px;
    cursor: pointer;

    // override default focus outline
    &:focus {
      outline: none;
    }

    input.br-select-display {
      padding-right: 5px; // leave some spacing for cursor
    }

    .br-select-display {
      @include ellipsis-text;
      padding: 0 0 0 8px; // override bs padding
      border: none;
      box-shadow: none;
      color: $newDarkGrey;
      font-family: 'Rubik', sans-serif;
      font-size: 11px;
      cursor: pointer;

      &::placeholder {
        font-size: 10px;
      }

      &.default-value {
        color: $secondaryGreyColor;
      }
    }

    &:after {
      content: url(../../../img/icons-select-down-arrow.svg);
      position: absolute;
      right: 8px;
      &:focus,
      &:hover {
        content: url(../../../img/icons-select-down-arrow-active.svg);
      }
    }

    &:focus-within {
      border: solid 1px $focusedElementBorderColor;
      box-shadow: $glowShadow;
      outline: none;
      background: $white;
    }

    &.disabled {
      cursor: not-allowed;
      .br-select-display {
        color: $disabledColor;
        cursor: not-allowed; /* override normalize */
      }
      &:focus {
        border-color: $disabledColor;
        cursor: not-allowed;
        box-shadow: none;
        outline: none;
      }
      &:after {
        @include disabledColorFilter;
      }
    }
  }

  // styling for the autosuggest input field
  .rbt-input-hint-container .br-select-display {
    height: 100%;
    box-shadow: $insetShadow;
    border-radius: 0;
    border-right: 1px solid $inputBorderColor;
    cursor: text;
  }

  .br-select-menu {
    width: 100%;
    position: absolute;
    z-index: 1000;
    margin-top: 4px;
    padding: 7px 14px;
    border-radius: 3px;
    box-shadow: 0 0 6px 2px $shadowColor;
    background-color: $white;
    overflow-x: hidden;
    overflow-y: auto;

    &.align-right {
      right: 0;
      left: unset;
    }

    .br-select-menu-item,
      // rbt - bootstrap style override
    [role='menuitem'],
    .dropdown-item {
      cursor: pointer;
      padding: 7px 0;
      font-size: 14px;
      color: $newDarkGrey;
      font-weight: 400;
      border-bottom: solid 1px transparent;
      &:focus,
      &:hover {
        outline: none;
        color: $lightblue;
        background-color: inherit;
      }
      &.disabled {
        color: $disabledColor;
        cursor: default;
      }
      // rbt - bootstrap style override
      &.active {
        color: $lightblue;
        background: inherit;
      }

      .rbt-highlight-text {
        background-color: inherit;
        color: $lightblue;
        font-weight: 600;
        padding: 0;
      }
    }
  }

  .br-select-menu-item {
    .retry-menu-item-text {
      color: $invalidColor;
      text-decoration: underline;
    }
  }
}