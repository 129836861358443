@import '../../src/scss/definitions';

$fileRowBorderWidth: 1px;
$fileRowBorderType: solid;
$fileRowBorderWidthType: $fileRowBorderWidth $fileRowBorderType;


.fileRow {
  display: flex;
  padding: 0 14px;
  flex-grow: 1;
  height: 68px;
  position: relative;
  margin-bottom: 4px;
  margin-top: 4px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: $fileRowBorderWidthType $separatorColor;
    bottom: -5px;
  }

  &:first-child {
    margin-top: 3px;
  }

  td {
    @include ellipsis-text;
    align-self: center;
  }

  &:last-child {
    margin-bottom: 3px;
    &:after {
      content: '';
      border-bottom: none;
    }
  }

  &:hover {
    background-color: $tableRowBackgroundColor;
  }

  &.link {
    cursor: pointer;

    &:hover {
      background-color: $tableRowBackgroundColor;

      .fileRowName {
        text-decoration: underline;
      }
    }

    &:focus-within {
      a {
        text-decoration: underline;
        outline: none;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-within {
    background-color: $tableRowBackgroundColor;
    position: relative;
    text-decoration: none;
    border-top: $fileRowBorderWidthType $focusedElementBorderColor;
    border-bottom: $fileRowBorderWidthType $focusedElementBorderColor;
  }
}


.fileIconCol {
  width: 30px;
  height: 30px;
}

.fileNameCol {
  width: 281px;
  margin-right: 15px;
  color: $primaryLinkColor;
  flex-grow: 1;
  padding: 0 0 0 8px;

  a {
    &:link, &:visited, &:hover {
      color: $primaryLinkColor;
    }
  }
}

.fileNameCol,
.fileTypeCol,
.fileLastUpdatedCol {
  min-width: 200px;
  font-family: $rubikFontFamily;
  font-size: 14px;
}

.fileTypeCol {
  margin-left: 10px;
  width: 110px;
}
.fileLastUpdatedCol {
  margin-left: 10px;
  width: 185px;
  margin-right: 10px;
}
