@import '../../../../scss/definitions';
@import '../../../common/dialogs/styles/modal-dialog-v2';

.file-history {
    @extend %modal-dialog-style-v2;
    @include modal-dialog-width(935px);

    .modal-footer {
        visibility: hidden;
    }
}

.file-history-panel {
    display: flex;
    flex-direction: column;

    .file-name {
        margin-bottom: 16px;
        font-size: 14px;
        color: $newDarkGrey;
    }

    .versions-table {
        font-size: 13px;
        max-height: 250px;
        overflow-y: auto;
        padding-top: 0;
        border: none;

        td, th {
            max-width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        th {
            font-weight: 500;
            padding-bottom: 4px;
            text-transform: none;
            height: 30px;
            vertical-align: middle;
        }
    }

}