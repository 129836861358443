@import '../../../../../src/frontend-common-libs/src/scss/definitions';

.org-breadcrumb {
  display: flex;
  align-items: center;

  .org-img {
    height: 23px;
    width: 23px;
  }

  .active-org-name {
    font-weight: normal;
    max-width: 120px;
    color: #697284;
    margin-left: 4px;
  }
}