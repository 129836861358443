@import "../src/frontend-common-libs/src/scss/definitions";

.side-menu-list {
  width: 46px;
  background: $leftBarBackground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  float: left;
  align-items: stretch;
  margin-left: 3px;
  margin-right: 3px;

  &.expanded {
    width: 118px;
  }

  li {
    .outer-div {
      display: flex;
    }

    .left-nav-item {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 7px;
      height: 29px;
    }

    .img-text {
      font-size: 9px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $headerGreyColor;
      margin-left: 5px;
      margin-bottom: 0;

      &.selected {
        color: $brGreen;
      }
    }

    .disabled-link {
      pointer-events: none;
      cursor: default;
    }

    .disabled-color {
      @include convertToDisabledColor;
    }

    .img-container {
      width: 30px;
    }

    .button-link {
      text-decoration: none;
    }
  }

  a {
    display: flex;
    align-items: center;
    margin-top: 9px;
    margin-bottom: 9px;
    height: 40px;
    border-bottom: solid 2px $leftBarBackground;
    border-top: solid 2px $leftBarBackground;
    outline: none;

    &:focus {
      outline: none;

      &:not(:active) {
        &:not(.selected) {
          @extend %focused-element-outline;
        }
      }
    }

    &.selected {
      border-bottom: solid 2px $brGreen;
      border-radius: 1px;

      &:focus {
        outline: none;
      }
    }
  }


  .expand-img {
    width: 12px;
    height: 12px;
  }

  .expand-btn {
    background-color: $leftBarBackground;
    border: none;
    padding: 8px;

    &:focus {
      @extend %focused-element-outline;
    }
  }

  .expand-li {
    align-self: flex-start;
    margin-top: 15px;
    margin-left: 7px;

    &:focus-within, :focus {
      border: none;
    }
  }
}
