@import '../../../src/frontend-common-libs/src/scss/definitions';

.user-projects-page {
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .projects-page-content {
    display: flex;
    margin: 14px 0 4px 0;
    height: inherit;

    .projects-left-panel {
      display: flex;
      width: 100%;
      max-width: 805px;

      .header-line {
        display: flex;
        align-items: center;
        font-size: 11px;
        height: 35px;
        margin: 0;
        padding-left: 68px;
        background-color: $toolbarBackgroundColor;
        color: $secondaryGreyColor;
        border-radius: 3px 3px 0 0;
        border: solid 1px $borderGrayColor;
        border-bottom: none;
      }

      .tenant-list {
        .tenant-rows {
          border: solid 1px $borderGrayColor;
          border-radius: 0 0 3px 3px;
          padding: 0;
        }
      }
    }

    .projects-right-panel {
      display: flex;
      flex-direction: column;
      width: 455px;
      margin-bottom: 40px;

      .projects-buttons {
        margin-bottom: 14px;
        align-self: end;
      }
    }
  }

  .vertical-separator {
    margin: 0 5px 0 5px;
  }

}