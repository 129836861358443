@import '../../../frontend-common-libs/src/scss/definitions';

$detailsLabelColor: #6a6a6a;

.details-row {
  height: auto !important;
  display: flex;
  margin-bottom: 4px !important;
  margin-top: 0 !important;

  .instrument-details {
    .conventional-pcr-details {
      width: 100%;
      display: flex;
      padding: 14px 16px 16px;
      flex-direction: row;
      background-color: $tableRowDetailsBackgroundColor;

      .run-details-panel {
        flex-grow: 1;
        margin-right: 24px;
      }

      .instrument-details-panel-body {
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        .general-instrument-details {
          width: 225px;
          .general-instrument-details-value {
            max-width: 130px;
          }
        }
        .software-firmware-versions {
          width: 218px;
        }
      }

      .instrument-details-panel {
        @mixin base-instrument-details-label {
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
        }

        .instrument-details-title-label {
          @include base-instrument-details-label();
          font-size: 14px;
          color: $newDarkGrey;
          margin-top: 2px;
          margin-bottom: 3px;
        }
      }
    }
  }
}
