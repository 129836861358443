@import '../src/frontend-common-libs/src/scss/definitions';

$fileRowGutter: 10px;
$fileRowBorderWidth: 1px;
$fileRowBorderType: solid;
$fileRowBorderWidthType: $fileRowBorderWidth $fileRowBorderType;

.recent-files-table-container {
  flex: 1 1 122px;
  display: flex;
  flex-direction: column;
  min-height: 122px;
  padding: 0 10px 10px;

  .files-titles {
    display: flex;
    flex-direction: row;
    padding: 0 20px 10px;

    .name-col {
      flex-grow: 1;
      font-size: 15px;
      font-family: $rubikFontFamily;
      color: $headerDarkColor;
      font-weight: 500;
    }

    .name-col,
    .type-col,
    .last-updated-col {
      margin-top: 5px;
      margin-bottom: 5px;
      min-width: 200px;
    }

    .type-col,
    .last-updated-col {
      margin-left: 10px;
      font-size: 12px;
      letter-spacing: 1.1px;
      color: $headerGreyColor;
      font-weight: 700;
    }
    .type-col {
      width: 110px;
    }
    .last-updated-col {
      width: 185px;
    }
  }

  .autosizer-content {
    overflow: auto;
    height: auto;
    border-radius: 3px;
    box-shadow: $primaryBoxShadow;
  }

  #recent-file-table {
    width: 100%;
    height: 100%;

    .file-table-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      min-height: 60px;
      width: 100%;
      height: 100%;
      padding: 0 10px;
    }
  }

  .error-files > span {
    margin-right: 10px;
  }

  .loader,
  .error-files,
  #no-files-message {
    text-transform: capitalize;
    border-radius: 3px;
    box-shadow: $primaryBoxShadow;
    background-color: #ffffff;
  }
}
