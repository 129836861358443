.custom-menu {
  position: absolute;
  margin-top: 4px;
  min-width: 160px;
  padding: 5px 0;
  font-size: 14px;
  background-color: $white;
  border: 1px solid $borderGrayColor;
  border-radius: 4px;

  .multi-select-filter {
    .menu-items-container {
      max-height: 190px;
      overflow-y: auto;
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
      &::-webkit-scrollbar {
        width: 0px;
      }
    }
    [role='menuitem'] {
      font-size: 12px;
      display: flex;
      padding: 0 15px;
      height: 30px;
      align-items: center;
      text-decoration: none;
      color: #333;
      .menu-item-text {
        @include ellipsis-text;
        flex: 1 0 1px;
      }
      i {
        margin-right: 10px;
      }
      .checked {
        color: $lightblue;
      }
      .pcr-data-color-circle,
      .total-item-count {
        flex: 0 0 auto;
      }

      &:hover {
        cursor: pointer;
      }

      &:hover,
      &:focus {
        background: none;
        outline: none;
        color: $lightblue;
      }
    }
  }

  .menu-item-divider {
    height: 1px;
    background-color: #e5e5e5;
    margin: 9px 0;
    overflow: hidden;
  }

  input {
    width: 148px;
    margin: 0 5px;
  }
}
