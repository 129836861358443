@import '../../../../frontend-common-libs/src/scss/definitions';

.cellText {
  color: $newDarkGrey;
}

.modelColumn {
  display: inline-flex;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 7px;

  span {
    margin-left: 9px;
  }
}

.nameCell:focus {
  text-decoration: none;
}