/* CSS for common button styles */

@import '../src/frontend-common-libs/src/scss/definitions';

$newDisabledColor: #c1cbc6;
$disabledBorderColor: #bfccc0;
$disabledBorder: solid 1px $disabledBorderColor;
$disabledBoxShadow: 0 1px 0 0 rgba(165, 165, 165, 0.65),
  inset 0 2px 0 0 rgba(255, 255, 255, 0.2);

@mixin btn-base {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid transparent;
  min-width: 125px;
  min-height: 36px;
  font-weight: 600;
  &:focus,
  &:active {
    outline: none;

    &:focus {
      outline: none;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 0 1px 1px $focussedColor;
    }
  }
}
@mixin new-btn-base(
  $backgroundColor: $primaryColor,
  $borderColor: $primaryColor,
  $color: $white
) {
  font-family: $rubikFontFamily;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.65),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.45);
  border-radius: 6px;
  min-width: 96px;
  min-height: 31px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 14px;
}

@mixin btn-primary-base {
  background: $primaryColor;
  border-color: $primaryColor;
  color: $white;
}

@mixin new-btn-base-color($backgroundColor, $borderColor, $color) {
  background: $backgroundColor;
  border-color: $borderColor;
  color: $color;
}

.btn-primary {
  @include btn-base;
  @include btn-primary-base;

  &:hover,
  &:focus {
    background: $primaryHighlightColor;
    border-color: $primaryColor;
    color: $white;
  }

  &:focus,
  &:active,
  &:active:focus,
  &:active:hover{
    background: $primaryHighlightColor;
    border-color: $primaryColor;
  }

  &[disabled] {
    background-color: $disabledButtonColor;
    border-color: $disabledColor;

    &:focus,
    &:active,
    &:hover {
      background-color: $disabledButtonColor;
      border-color: $disabledColor;
      color: $disabledColor;
      cursor: not-allowed;
    }
  }
}

.btn-secondary {
  @include btn-base;
  background: $secondaryColor;
  color: $secondaryColorText;
  border-color: $primaryColor;

  &:hover,
  &:focus:hover {
    background: $secondaryColorHighlight;
    border-color: $primaryColor;
    color: $secondaryColorText;
  }

  &:focus {
    background: $secondaryColor;
    border-color: $primaryColor;
    color: $secondaryColorText;
  }
}

.open > .dropdown-toggle.btn-secondary.btn-default,
.open > .dropdown-toggle.btn-secondary.btn-default:focus,
.open > .dropdown-toggle.btn-secondary.btn-default:hover {
  color: $secondaryColorText;
  border-color: $primaryColor;
}

.btn-own-gray.btn {
  @include btn-base;
  margin-left: 10px;
  background-color: $white;
  border-color: #777;
  color: #777;
  min-width: 100px;

  &:hover,
  &:focus {
    margin-left: 10px;
    background-color: $white;
    border-color: #777;
  }
}

.btn-icon {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.btn-default-own {
  background: $secondaryColor;
  border-color: $secondaryColor;
  color: $secondaryColorText;
}

.btn-info-own {
  background: #ebf7f0;
  border-color: #ebf7f0;
  color: $secondaryColorText;
}

.btn-bg-888 {
  background-color: #888 !important;
  color: $white !important;
}

.form-btn {
  @include btn-base;
  width: 392px;
  height: 40px;
  margin: 24px 0 10px 0;
}

.btn-circle {
  width: 24px;
  height: 24px;
  padding: 4px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  background: #025b2a;
  color: white;

  &:hover {
    color: white;
  }
}

.btn-action {
  padding: 2px;
  background: transparent;
  &:focus {
    color: $primaryColor;
  }
}

.open > .dropdown-toggle.btn-primary {
  @include btn-primary-base;

  &:focus,
  &:hover {
    @include btn-primary-base;
  }
}

a.btn-primary {
  appearance: button;
}

.btn-noborder,
.btn-noborder:visited {
  font-weight: 600;
  line-height: 1.33;
  color: $primaryColor;
  padding: 0px 6px;
  margin: 0px 6px;
  background: transparent;
  border: none;

  &:hover {
    line-height: 1.33;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
    box-shadow: 0 0 1px 1px $focusedElementBorderColor;
  }

  &.link {
    color: $lightblue;
    font-size: 13px;
    &[disabled] {
      color: $disabledColor;
      text-decoration: none;
    }
  }
}

@mixin new-btn-primary-states($primary, $border) {
  &:hover {
    background: $primary;
    border-color: $border;
    color: $white;
  }

  &:focus:hover {
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.45),
      inset 0 1px 0 0 rgba(0, 0, 0, 0.65);
  }

  &[disabled] {
    color: $white;
    background-color: $newDisabledColor;
    border: $disabledBorder;
    box-shadow: $disabledBoxShadow;
  }
}

.new-btn {
  @include new-btn-base;

  &:focus {
    outline: 1px solid $focusedElementBorderColor;
    outline-offset: 2px;
    box-shadow: none;
  }

  &.link {
    border: none;
    box-shadow: none;
    color: $lightblue;
    background: none;
    font-size: 13px;
    min-width: 0;
    min-height: 0;
    padding: 0;

    &:focus,
    &:hover {
      text-decoration: underline;
    }

    &[disabled] {
      color: $disabledColor;
      text-decoration: none;
    }
  }

  &.primary {
    @include new-btn-base-color($primaryColor, $primaryColor, $white);
    @include new-btn-primary-states($primaryBorderColor, $newDarkGreen);
    border: 1px solid $primaryBorderColor;
  }

  &.secondary {
    @include new-btn-base-color(
      $secondaryBackgroundColor,
      $secondaryBorderGrayColor,
      #0366b2
    );
    @include new-btn-primary-states($darkGrey, $newBorderGrayColor);
    font-weight: normal;
  }

  &.utility,
  &.ghost {
    border-radius: 5px;
    border: solid 1px $lightblue;
    font-size: $primaryFontSize;
    font-family: $rubikFontFamily;
    font-weight: 400;
    box-shadow: none;
    padding: 5px 14px;
    border-color: transparent;
    min-height: 24px;
    background-color: $lightblue;
    color: white;

    &:hover {
      background-color: $secondaryDarkBlue;
    }

    &.disabled,
    &[disabled] {
      background-color: $lightGray;
      border: transparent;
      box-shadow: none;
      cursor: not-allowed;
    }
  }

  &.ghost {
    background-color: $white;
    border-color: $lightblue;
    color: $lightblue;

    &:hover {
      border-color: $secondaryDarkBlue;
      color: $secondaryDarkBlue;
      background-color: $white;
    }

    &.disabled,
    &[disabled] {
      color: $lightGray;
      background-color: $white;
      border: solid 1px $lightGray;
      box-shadow: none;
      cursor: not-allowed;
    }

    &.selected {
      background-color: $secondaryDarkBlue;
      color: $white;
      border-color: $secondaryDarkBlue;

      &.disabled,
      &[disabled] {
        background-color: $lightGray;
        border: transparent;
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }

  i {
    margin-right: 10px;
  }
}

button,
a,
span {
  &.new-btn-icon {
    display: flex;
    flex-direction: row;
    font-family: $rubikFontFamily;
    font-size: 12px;
    color: $lightblue;
    align-items: center;
    border: none;
    background: inherit;
    padding: 0;

    cursor: pointer;
    .icon {
      margin-right: 4px;
      width: 20px;
      height: 20px;
      background-color: transparent;
      padding: 0;
      outline-offset: 2px;

      @include defaultColorFilter;
    }
    &.vertical {
      flex-direction: column;
      font-size: 11px;

      .icon {
        height: 26px;
        width: 26px;
        margin-right: 0;
        margin-bottom: 3px;
      }
    }

    &:hover,
    &:active {
      background-color: inherit;
      outline: none;
      color: $secondaryDarkBlue;
      .icon {
        @include hoverColorFilter;
      }
    }

    &:focus:hover {
      color: $secondaryDarkBlue;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:focus {
      background-color: inherit;
      outline: 1px solid $focusedElementBorderColor;
      box-shadow: none;
      color: $lightblue;
      outline-offset: 2px;
    }

    &:focus:active {
      background-color: inherit;
      outline: none;
      color: $secondaryDarkBlue;
    }
  }

  &.disabled,
  &[disabled],
  &[aria-disabled='true'] {
    background-color: inherit;
    font-size: 12px;
    color: $disabledColor;
    cursor: not-allowed;
    & .icon {
      @include disabledColorFilter;
    }
    &:hover {
      text-decoration: none;
      color: $disabledColor;

      & .icon {
        @include disabledColorFilter;
      }
    }
  }
}

.btn-arrow {
  font-family: $rubikFontFamily;
  color: $lightblue;
  background: inherit;
  width: 16px;
  height: 16px;
  padding: 0px; /* override bootstrap style */
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;

  &:focus,
  &:active,
  &:focus:active {
    outline: 1px solid rgba(255, 102, 0, 0.7);
    outline-offset: 2px;
    box-shadow: none;
    color: $lightblue;
  }

  &.left-arrow {
    background-image: url(../img/circle-arrow-left.svg);

    &:hover {
      text-decoration: none;
      background-image: url(../img/circle-arrow-left-hover.svg);
    }

    &.disabled {
      background-color: inherit;
      background-image: url(../img/circle-arrow-left-dim.svg);
    }
  }

  &.right-arrow {
    background-image: url(../img/circle-arrow-right.svg);

    &:hover {
      text-decoration: none;
      background-image: url(../img/circle-arrow-right-hover.svg);
    }

    &.disabled {
      background-color: inherit;
      background-image: url(../img/circle-arrow-right-dim.svg);
    }
  }
}

.button-group {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-top: auto;

  &.right {
    justify-content: flex-end;
  }
}

.hover-image-button {
  &:hover {
    filter: brightness(70%);
  }
  @extend %focus-outline;
  &:disabled {
    @include convertToDisabledColor;
  }
}