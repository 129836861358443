@import "../../../frontend-common-libs/src/scss/definitions";

.buttonmenu-item.buttonmenu-text{
  &:hover span {
    color: $lightblue;
  }
  &:focus span {
    color: $lightblue;
  }
  &:hover a {
    text-decoration: none;
  }
}
