@import "../../../../frontend-common-libs/src/scss/definitions";

.loginContainer {
  overflow: auto;
  height: 100%;
}
.verificationSuccessfulTitle,
.loginNotificationAccountActivation{
  font-size: 19px;
  color: $newDarkGrey;
  text-align: center;
  p {
    margin-bottom: 0;
  }
}