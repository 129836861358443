@import '../../../frontend-common-libs/src/scss/definitions';

.pcr-protocol-page {
  display: flex;
  flex-direction: column;
  background: white;
  min-height: 100%;

  .back-link {
    color: $primaryColor;
    display: flex;
    align-items: center;
    margin-left: 25px;
    @extend %no-focus-outline-on-click;
  }

  .top-row {
    display: flex;
    align-items: center;
    min-height: 40px;
  }
}