@import '../../../../src/frontend-common-libs/src/scss/definitions';

.edit-organization-page {
  padding: 10px 22px 24px 21px;
  display: flex;
  flex-direction: column;
  background-color: $grayBackground;
  min-width: 100%;
  width: fit-content;
  height: 98%;

  .back-link {
    font-size: 12px;
    margin-bottom: 5px;
    @extend %no-focus-outline-on-click;
  }

  .edit-organization {
    display: flex;
    flex-direction: column;
    flex: 1;

    .organization-name {
      display: flex;
      color: $newDarkGrey;
      font-size: 16px;
      font-weight: 500;
      margin-top: 4px;
      max-width: 805px;
    }

    .rename-button {
      margin: -4px 8px 0 8px;
      background: none;
      border: none;
    }

    .org-info-row {
      display: flex;
      flex-direction: row;

      .org-img {
        align-self: flex-start;
        height: 67px;
        min-width: 111px;
        margin: 3px 21px 12px 0;
        background: #e2e4e6;
        border-radius: 9px;
        padding: 4px;
      }

      .org-description-column {
        display: flex;
        flex-direction: column;
        overflow: auto;
        font-size: 11px;
        color: $secondaryGreyColor;

        .org-description {
          margin: 4px 16px 7px 0;
          max-width: 526px;
          height: 42px;
          max-height: 42px;
          overflow-x: hidden;
          overflow-y: auto;
          word-break: break-word;
        }

        .members-count {
          margin-top: auto;
          margin-bottom: 9px;
        }
      }
    }

  }
}