@import '../../../../frontend-common-libs/src/scss/definitions';
@import '../../../../frontend-common-libs/src/scss/input';

.run-details {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  margin: 22px 15px 21px 16px;

  .vertical-separator {
    border-left: 1px solid $tableRowDetailsTitleColor;
    margin: 0px 50px 0px 50px;
  }

  .left-panel {
    display: flex;
    flex-direction: column;
    width: 360px;

    .row-content {
      display: flex;
      flex-direction: row;
      column-gap: 40px;
    }

    .run-notes {
      margin-top:auto;
      margin-bottom: 10px;
    }
  }

  .right-panel {
    display: flex;
    flex-direction: row;

    .column-content {
      display: flex;
      flex-direction: column;
      width: 302px;
      padding-right: 8px;
    }

    .last-column-content {
      width: 220px;
    }
  }


  .run-report-link {
    color: $lightblue;
    font-size: 13px;
    font-weight: 500;
    @extend %no-focus-outline-on-click;
  }


  .input-label {
    color: $newDarkGrey;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 1px;
  }

  .save-button {
    align-self: center;
    white-space: nowrap;
    min-width: 60px;
  }

  .run-status-success {
    margin-right: 4px;
    vertical-align: baseline;
  }

  .run-status-failure {
    margin-right: 5px;
    vertical-align: baseline;
    color: #D12C2B;
  }

}
