@import '../src/frontend-common-libs/src/scss/definitions';
.explore-sidebar {
  position: relative;
  min-width: 78px;
  height: auto;
  border-bottom-left-radius: 20px;
  background-color: rgba(51, 71, 114, 0.6);
  padding: 0 0 8px 10px;
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  font-family: 'Rubik', sans-serif;
  font-family: $rubikFontFamily;
  cursor: pointer;

  &.expanded {
    width: 282px;
    min-width: 282px;
    height: 100%;
    padding: 0;
    border-radius: initial;
    background: linear-gradient(rgba(51, 71, 114, 0.8), rgba(51, 71, 114, 0.8)),
      url(../img/scientist-image.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
    cursor: default;
  }

  .scroll-section {
    height: 100%;
    overflow-y: auto;
    padding: 0 16px 32px 32px;
  }

  .no-scroll-section {
    width: 100%;
    text-align: right;
  }

  .expand-btn {
    background-color: transparent;
    border: none;
    margin: 12px 12px 5px 0;
    padding: 0;
    position: relative;
    height: 29px;
    width: 29px;

    &:focus {
      outline: 1px solid $focusedElementBorderColor;
    }
  }

  h2 {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 3px;
    margin-top: 0;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 15px;
    font-weight: 500;
    margin: 0 4px 0 0;
  }

  ul {
    font-size: 15px;
    line-height: 1.8;
    color: $homeSidebarYellow;
    list-style-type: none;
    padding: 0;
  }

  .margin-bottom-50 {
    margin-bottom: 50px;
  }

  .text-yellow {
    color: $homeSidebarYellow;
  }

  .link {
    display: block;
    font-size: 15px;
    line-height: 1.8;
    color: $homeSidebarYellow;
    font-weight: normal;
  }

  .eap-section {
    margin-top: 50px;
  }
}
