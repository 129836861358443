@import '../../../src/frontend-common-libs/src/scss/definitions';

.edit-project {
    display: flex;
    flex-direction: column;
    background-color: $toolbarBackgroundColor;
    border-radius: 3px;

    .header {
        display: flex;
        flex-direction: column;
        border-radius: 3px 3px 0 0;

        .project-name {
            display: flex;
            margin: 8px 8px 8px 16px;
            color: black;
            font-size: 16px;

            .rename-button {
                background: none;
                border: none;
            }
        }

        .project-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: -6px 12px 0px 6px;

            .project-created {
                min-width: 120px;
                margin-left: 6px;
            }
        }
    }

    .loader {
        font-size: 30px;
        margin: 8px;
        align-self: center;
    }
}