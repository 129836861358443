@import '../src/frontend-common-libs/src/scss/definitions';


#verify-account {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    height: 100%;

    #title-separator {
        height: 1px;
        background-color: #979797;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    #content {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $rubikFontFamily;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: $newDarkGrey;

        .user-email {
            font-weight: 500;            
        }

        #link-resend {
            color: $primaryColor;
            font-weight: 500;
            margin-top: 10px;
            font-size: 14px;
        }

        #verify-account-title{            
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
        }

        #verification-email-img {
            width: 44px;
            height: 42px;
            margin-top: 77px;
            margin-right: 15px;
        }        

        .notification-title{
            font-weight: 500;
            margin: 16px 0 14px 0;
        }

        #biorad-icon,
        #brio-icon {
            width: 87px;
            height: 24px;
        }

        #verification-icon-header {
            justify-content: flex-start;
            margin-bottom: 54px;
            margin-left: 9%;
        }

        .description {
            max-width: 558px;
            #thanks-message {
                margin-bottom: 7px;
            }
        }

        #children-container{
            display: flex;
        }
    }
}

@media (max-width: 665px) {
    #verify-account #content {
        flex-direction: column;
        align-items: flex-start !important;
        h1 {
            text-align: center;
        }
        .description {
            text-align: justify;
        }
        #children-container {
            flex-direction: column;
            align-items: center;
        }
        #verification-icon-header {
           margin: 0px;
           justify-content: center;
        }
        #verification-email-img {
            margin: 52px 0 15px 0;
        }
    }
}
