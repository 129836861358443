@import '../../../../frontend-common-libs/src/scss/definitions';

.startRunModal {
  background-color: rgba(103, 117, 126, 0.55);

  :global {
    .modal-dialog {
      width: 350px;
      height: 183px;
      top: 25%;
      list-style: none;
    }

    .btn {
      height: 32px;
      border-radius: 6px;
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.65), inset 0 2px 0 0 rgba(255, 255, 255, 0.45);
      border: solid 1px $primaryBorderColor;
      font-size: 14px;
    }

  }
}

.startRunImage {
  width: 45px;
  height: 45px;
}

.centerContent {
  display: block;
  justify-content: center;
}

.contentHeader {
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
}

.contentBold {
  font-weight: bold;
}

.contentMessage {
  margin-top: 6px;
}

.contentInstrumentsList {
  margin-top: 20px;
}

.startBtn {
  width: 60px;
  background-color: $primaryBorderColor;
  margin-left: 16px !important;
  color: #fff;
}

.cancelBtn {
  width: 73px;
  background-color: $secondaryBackgroundColor;
  color: #0366b2;
}
