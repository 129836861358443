@import "../../../frontend-common-libs/src/scss/definitions";

.container {
  &.container {
    overflow: auto;
    height: 100%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icons {
      display: flex;
      justify-content: space-between;
      width: 140px;
      margin-bottom: 40px;
    }
  }
}
