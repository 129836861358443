@import '../../../frontend-common-libs/src/scss/definitions';

#instrument-fleet {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 42px;
  margin: 0 13px 0 14px;
  background-color: $secondaryColor;

  .fleet-management-header {
    font-size: 16px;
    font-weight: 500;
    color: $brGreen;
    margin-bottom: 20px;
  }
}