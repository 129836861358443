@import '../../../../src/frontend-common-libs/src/scss/definitions';

.org-tabs {
  display: flex;
  flex-direction: column;
  flex: 1;

  .org-resources {
    border-bottom: 1px solid $borderGrayColor;
    display: flex;

    .org-resource {
      font-size: 12px;
      font-weight: 500;
      color: $newDarkGrey;
      text-align: center;
      width: 56px;
      border: 0;
      background: transparent;
      margin-right: 48px;
      text-decoration: none;
      text-transform: capitalize;
      @extend %focus-outline;
    }

    .selected-resource {
      border-bottom: 2px solid $primaryLinkColor;
    }
  }
}
