@import "../../../../frontend-common-libs/src/scss/definitions";

.container {
  &.container {
    height: auto;
    margin: 0 auto;
    text-align: center;
    width: 501px;

    .headerContainer,
    .errorMessage {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .errorIcon {
      margin-right: 9px;
      height: 26px;
      width: 26px;
    }

    .completeMessage,
    .help,
    .errorMessage {
      margin-top: 23px;
      color: $newDarkGrey;
      white-space: pre;
      line-height: 1.6;
      font-size: 16px;

      strong {
        font-weight: 600;
      }

      a {
        color: $newDarkGreen;
      }
    }

    p.completeMessage {
      margin: 0;
      word-break: break-all;
      white-space: normal;
    }

    .errorHeader {
      font-size: 28px;
      color: #dd0000;
      font-weight: normal;
      margin: 1px 0 0;
      line-height: 0.86;
    }

    .button {
      width: 80px;
      min-width: 80px;
      margin-top: 25px;
      margin-bottom: 13px;
    }
  }
}

