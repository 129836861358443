.footer-bar {
  display: flex;
  flex: 0 0 40px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background-color: $white;
  border-top: solid 1px $borderGrayColor;

  .footer-nav-button {
    display: flex;
    flex: 0 0 auto;
    color: $newDarkGrey;
    background: transparent;
    font-size: 16px;
    font-weight: 300;
    border: solid 1px transparent;
    box-shadow: 0 0 8px transparent;
    text-decoration: none;

    &.disabled {
      pointer-events: none;
      -webkit-user-select: none;
      .step-text {
        color: $disabledColor;
      }
    }
    &.hidden {
      display: none;
    }
    &:active {
      text-decoration: none;
    }
    &:focus {
      box-shadow: none;
      outline: 1px solid rgba(255, 102, 0, 0.7);
    }
    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &.action-button {
      flex: 1 0 1px;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    .btn-arrow:hover {
      text-decoration: none;
    }

    &.for-right-arrow {
      padding-left: 12px;
    }

    &.for-left-arrow {
      margin-left: -52px; /* left-navbar offset (collapsed) to center nav in viewport */
      padding-right: 18px;
    }

    $boldWeight: 500;

    &.active-step {
      cursor: default;
      font-family: 'Rubik', sans-serif;
      font-weight: $boldWeight;
      &:hover {
        text-decoration: none;
      }
    }

    .step-text {
      font-family: $rubikFontFamily;
      flex: 1 0 auto;
      margin: 0 11px;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
      // invisible pseudo-element needed to prevent bolded
      // text from shifting other element in nav bar
      &::after {
        display: block;
        content: attr(title);
        font-weight: $boldWeight;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
}
