/* CSS for modal pop-ups */

@import "../src/frontend-common-libs/src/scss/definitions";

.own-modal .modal-header-own,
.protocol-step-modal .modal-header-own {
  background: $primaryColor;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
}

.own-modal .modal-header .close,
.protocol-step-modal .modal-header .close,
.close:hover .close:focus {
  color: #fff;
  opacity: 1;
  background-color: initial;
  border-color: initial;
}

.own-modal .form-group {
  height: 85px;
  margin-bottom: 0;
  position: relative;

  .form-control {
    height: 40px;
    border-radius: 2px;
    box-shadow: none;
    border: none;
  }

  &.icon-left-space .form-control {
    padding-left: 45px;
  }
}
