#landing-page {
  .hero-banner {
    width: 100%;
    position: relative;
    margin-bottom: 40px;

    .banner-img {
      min-width: 880px;
      width: 100%;

      @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
        min-width: 100px;
      }
    }

    .banner-images {
      @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
        position: inherit;
      }
    }

    .early-access-label {
      position: absolute;
      top: 0;
      left: 0;

      @media only screen and (max-width: $mobilePortraitMaxWidth) {
        width: 134.4px;
        height: 132.3px;
      }
    }

    .caption {
      position: absolute;
      top: 179px;
      margin-left: 50px;
      width: 390px;

      @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
        text-align: center;
        width: 100%;
        position: inherit;
        top: 0px;
        margin-left: 0px;
        margin-bottom: 14px;
      }

      h1 {
        color: #159deb;
        font-family: $rubikFontFamily;
        font-size: 58px;
        font-weight: 100;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 14px;

        @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
          font-size: 24px;
        }
      }

      p {
        color: #4d4d4d;
        font-family: $rubikFontFamily;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.34;
        margin-bottom: 0px;

        @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
          font-size: 14px;
          padding: 10px;
        }
      }
    }
  }

  #landing-content {
    padding: 0px 30px;
  }

  .blurb-box-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;

    .box {
      display: flex;
      flex-direction: column;
      width: 32.5%;
      background-color: #335669;
      padding: 28px 25px 22px 25px;
      white-space: pre-wrap;

      &:last-child {
        margin-right: 0;
      }

      h2 {
        margin-top: 0;
        font-size: 34px;
        color: #149deb;
        font-family: $rubikFontFamily;
        font-weight: 100;
        text-align: center;
        margin-bottom: 27px;

        @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
          font-size: 30px;
        }
      }

      p {
        font-size: 18px;
        color: $white;
        font-family: $rubikFontFamily;
        font-weight: 300;
        margin: 0;

        @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
          font-size: 16px;
        }
      }
    }

    @media only screen and (max-width: $tabletLandscapeMaxWidth) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .box {
        margin-right: 0;
        margin-bottom: 17px;
        width: 100%;
        white-space: pre-wrap;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .coming-soon-callout {
    text-align: right;
    margin: 17px 0 0 0;
    font-size: 18px;
  }
}

.benefit-container {
  font-family: $rubikFontFamily;

  .benefit-title {
    font-size: 40px;
    font-weight: 300;
    margin: 0 0 10px 0;
  }

  .benefit-boxes-container {
    display: flex;
    flex-direction: column;

    .benefit-box-container {
      display: flex;
      background-color: #efefef;
      margin-bottom: 17px;
      padding: 0 38px 0px 38px;
      min-height: 185px;

      @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
        display: block;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      img {
        align-self: center;
        width: 133px;
        height: 133px;
        margin-right: 42px;

        @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
          width: 53px;
          height: 53px;
          margin: 10px 0px 0px 0px;
          position: absolute;
        }
      }

      .benefit-text-wrapper {
        flex-grow: 1;
        padding: 18px 0;

        @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
          text-align: left;
        }

        h3 {
          font-size: 28px;
          font-weight: 300;
          margin: 0px 0px 12px 0px;

          @media only screen and (max-width: $mobileLargerPortraitMaxWidth) {
            margin: 0px 0px 30px 70px;
          }
        }

        ul {
          list-style-type: square;
          font-size: 18px;
          padding-left: 20px;
          margin-bottom: 0;
          white-space: pre-wrap;
        }
      }
    }
  }
}
