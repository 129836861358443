@import "../../../../frontend-common-libs/src/scss/definitions";

.container {
  &.container {
    width: 392px;
    height: auto;
    margin: 0 auto;

    .headerContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      p {
        color: $newDarkGrey;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
      }
    }

    .confirmationInfoWrapper {
      display: flex;
      flex-direction: column;
    }

    .infoWrapper {
      display: flex;
      justify-content: space-between;
    }

    .instrumentLabel {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.31;
      color: $newDarkGrey;
      min-width: 190px;
      text-align: right;
    }

    .instrumentAttribute {
      font-size: 13px;
      line-height: 1.31;
      color: $newDarkGrey;
      min-width: 190px;
      text-align: left;
      margin-bottom: 6px;
    }

    .buttonsWrapper {
      display: flex;
      margin-top: 34px;
      flex-direction: column;
      align-items: center;
    }

    .confirmButton {
      width: 300px;
    }

    .cancelButton {
      margin-top: 31px;
      line-height: 1.29;
    }
  }

}

