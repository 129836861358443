@import '../../../src/frontend-common-libs/src/scss/definitions';

.project-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding-left: 2px;

  .project-img {
    display: none;
  }

  &:focus-within {
    outline: 1px solid $focusedElementBorderColor;
  }

  .project-dropdown-container {

    #project-dropdown {
      width: 177px;
      background: transparent;
    }

    .br-select-menu {
      max-height: 250px;
      width: 214px;
      margin-left: -30px;
    }

    .br-select-button {
      border: none;
      border-radius: 0;

      &:focus-within {
        border: none;
        box-shadow: none;
        background: transparent;
      }
    }

    .br-select-display {
      font-size: 14px;
      font-weight: normal;
      color: $headerGreyColor;
      padding-left: 7px;
    }

    .br-select-menu-item {
      text-align: start;
      padding-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 13px;

      &.selected {
        &:before {
          content: url(../../../img/checkmark-dark-gray.svg);
          position: absolute;
          left: 5px;
        }
      }
    }
  }

}