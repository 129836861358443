@import '../../../src/frontend-common-libs/src/scss/definitions';

.org-project-selector {
  height: 27px;
  margin-top: 12px;
  padding: 0 6px;
  border-radius: 14px;
  border: solid 1px #87929b;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:focus-within {
    outline: 1px solid $focusedElementBorderColor;
  }

  .project-img {
    height: 18px;
    margin-left: 6px;
  }

  .project-dropdown-container {
    margin-left: auto;

    #project-dropdown {
      width: 82px;
      background: transparent;
      margin-left: 4px;
    }

    .br-select-menu {
      max-height: 250px;
      width: 182px;
    }

    .br-select-button {
      border: none;
      padding-right: 18px;

      &:after {
        content: url(../../frontend-common-libs/src/img/icons-select-down-arrow-green.svg);
        position: absolute;
        right: 8px;

        &:focus,
        &:hover {
          content: url(../../frontend-common-libs/src/img/icons-select-down-arrow-green.svg);
        }
      }

      &:focus-within {
        border: none;
        box-shadow: none;
        background: transparent;
      }
    }

    .br-select-display {
      font-size: 9px;
      font-weight: normal;
      color: $headerGreyColor;
      padding-left: 0px;
    }

    .br-select-menu-item {
      text-align: start;
      padding-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.selected {
        &:before {
          content: url(../../../img/checkmark-dark-gray.svg);
          position: absolute;
          left: 5px;
        }
      }
    }
  }

  .project-dropdown-collapsed {
    #project-dropdown {
      width: 10px;
      margin-left: 1px;
    }

    .br-select-display {
      display: none;
    }
  }
}