@import '../../../../frontend-common-libs/src/scss/definitions';

.run-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .run-page-content {
    flex: 1 0 1px;
    margin: 2px 14px 14px 12px;
    color: $newDarkGrey;
    background: white;
    overflow: auto;
    border-radius: 0 0 3px 3px;
    border: 1px solid $newBorderGrayColor;
    border-top: 0;
    display: flex;
    flex-direction: row;
    height: 100%;
  }

}
