#home-container {
  background-color: #ebeef0;
  background-image: url('/img/b-r-marcom-shapes.png');
  background-repeat: repeat-y;
  background-origin: content-box;
  background-position: right top;
  display: flex;
  height: 100%;
  padding-left: 22px; /*this value was changed from 32 to 22, because the recent-file-table needed 10px of padding in order of showing the border shadow correctly*/
  #home-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 24px;
    padding-bottom: 4px;
  }
  #explore-sidebar {
    margin-left: 22px; /*this walue was changed from 32 to 22, because the recent-file-table needed 10px of padding in order of showing the border shadow correctly*/
  }
}
