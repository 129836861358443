@import "../../../../frontend-common-libs/src/scss/definitions";

.container {
  &.container {
    width: 358px;
    height: auto;
    margin: 0 auto 0 auto;

    .text {
      display: flex;
      flex-direction: column;

      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
      }

      .helper {
        font-size: 16px;
        color: $lightGrayText;
        white-space: pre;
        line-height: 1.29;
        margin-bottom: 24px;
      }

      a {
        color: $newDarkGreen;
        margin-left: 4px;
        margin-right: 4px;
      }

    }

    form {
      .codeInputWrapper {
        display: flex;
        flex-direction: column;

        input[type='text'] {
          width: 100%;
          border: 1px solid $inputBorderColor;
          color: $newDarkGrey;
          border-radius: 3px;
          height: 30px;
          box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
          padding: 8px;
          font-size: 13px;

          &:focus {
            border: solid 1px $focusedElementBorderColor;
            box-shadow: $insetShadow, $glowShadow;
            outline: none;
          }
        }
      }

      .button {
        width: 100%;
        font-size: 14px;
        margin-top: 20px;
      }
    }

    .labelWrapper {
      display: flex;
      justify-content: space-between;

      .formLabel {
        line-height: 1.27;
        margin-top: 0;
        font-weight: normal;
        font-size: 11px;
      }

      .formLabelError {
        margin: 0;
        font-size: 11px;
        font-weight: 500;
        text-align: right;
        color: $invalidColor;
      }
    }
  }

  .userCodeInput::placeholder {
    color: $secondaryGreyColor;
  }
}

