/* CSS Styles for the PCR protocol and plate editors */
$alignmentMargin: 60px;

.equal-list-wrapper .btn-primary {
  &:hover,
  &:active {
    background: #fff;
    background-color: #fff;
    border: solid 1px #dfdfdf;
    color: #161616;
  }
}

.protocol-text-scroll {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.protocol-chart {
  overflow: auto;
}

.protocol-step-modal .modal-content {
  border-radius: 0;
  width: 700px;
}

.protocol-step-modal .modal-footer {
  border-top: 2px solid rgba(229, 229, 229, 0.63);
}

.protocol-step-modal .modal-footer button[disabled] {
  border-color: #c3c3c3;
  background: #c3c3c3;
  color: rgba(0, 0, 0, 0.47);
  &:hover,
  &:focus {
    border-color: #c3c3c3;
    background: #c3c3c3;
    color: rgba(0, 0, 0, 0.47);
  }
}

.protocol-bottom-bar {
  padding: 10px 18px 10px 0px;
  display: flex;
  justify-content: flex-end;
  .new-btn {
    margin-left: 18px;
  }
}

#protocol-data {
  flex: 1 1 1px;
  min-height: 80px;
  max-height: 100%;
}

.protocol-data-text {
  padding-top: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  font-weight: 500;

  .hover-effect {
    &.active {
      background-color: $protocolStepBoxActiveBackground;
      &:hover {
        background-color: $protocolStepBoxActiveBackground;
      }
    }
    &:hover {
      background-color: $tableRowDetailsBackgroundColor;
      color: $lightblue;
      border: 1px solid $lightblue;
    }
  }
}

.equal-list-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  .dropdown-toggle {
    width: 100%;
    height: 40px;
    background: #fff;
    font-weight: 600;
    color: #161616;
    font-size: 14px;
    text-align: left;
    padding-top: 10px;
    border-radius: 2px;
    background-color: #fff;
    border: solid 1px #dfdfdf;
  }
  .dropdown-menu {
    width: 100%;
    & li {
      width: 100%;
    }
  }
  .dropdown {
    display: block;
  }
  .form-group {
    height: 85px;
    .step-type-label {
      width: 100%;
    }
  }
  .toggle {
    position: relative;
    padding: 0;
    margin: 100px auto;
    display: block;
    height: 30px;
    p span {
      position: absolute;
      top: 2px;
      left: 0;
      z-index: 5;
      display: block;
      width: 50%;
      margin-left: 100px;
      text-align: center;
      font-size: 14px;
      &:first-child {
        color: #fff;
        transition: 0.3s ease-out;
      }
      &:last-child {
        left: 50%;
      }
    }
    label,
    p {
      line-height: 30px;
      vertical-align: middle;
    }
    input {
      position: absolute;
      opacity: 0;
      z-index: 5;
      &:checked ~ {
        p span {
          &:last-child {
            color: #fff;
            transition: 0.3s ease-out;
          }
          &:first-child {
            color: #888;
            transition: 0.3s ease-out;
          }
        }
      }
      &:focus ~ .slide-button {
        background-color: #fff;
        color: #888;
        font-weight: bold;
        text-align: center;
        text-shadow: 1px 1px 1px #191b1e;
        border-radius: 2px;
        padding: 2px 2px;
        border: solid 1px #dfdfdf;
      }
    }
    p {
      position: absolute;
      left: -100px;
      width: 100%;
      margin: 0;
      padding-right: 100px;
      text-align: left;
      font-family: OpenSans-SemiBold, 'Helvetica Neue', helvetica, arial,
        sans-serif;
      font-size: 0.8em;
    }
  }
  .switch {
    background-color: #fff;
    color: #888;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 1px #191b1e;
    border-radius: 2px;
    padding: 2px 2px;
    border: solid 1px #dfdfdf;
    -webkit-animation: bugfix infinite 1s;
    input:checked + label {
      color: #333;
    }
    p {
      color: #333;
      text-shadow: none;
    }
    span {
      color: #888;
    }
  }
  li {
    float: left;
    position: relative;

    .checkbox {
      display: inline-block;
      text-align: left;
      margin-top: auto;
      margin-bottom: 0;
      height: 40px;
      width: 115px;
    }
  }
  .toggle * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggle label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
}

.protocol-container {
  background-color: $white;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.protocols-link {
  a {
    cursor: pointer;
    color: $primaryColor;
    &:active {
      font-weight: bold;
      cursor: default;
      color: $primaryColor;
    }
    &:focus {
      outline: 1px solid $focusedElementBorderColor;
    }
  }
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  background: #fff;
}

.chevron {
  &::before {
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: '';
    display: inline-block;
    height: 0.75em;
    position: relative;
    top: 0.25em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.75em;
    color: $primaryColor;
  }
  &.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
    margin-right: 10px;
  }
}

.protocol-details {
  flex: 1 0 1px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-family: $rubikFontFamily;

  .title-field,
  #protocol-chart,
  #protocol-settings,
  #protocol-step-list {
    padding: 0 16px 0 18px;
  }
  .title-field {
    margin-bottom: 10px;
  }
  #protocol-step-list {
    padding-bottom: 16px;
  }

  .form-control,
  .dropdown-toggle {
    font-weight: normal;
    font-size: 11px;
  }
}

.protocol-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  #protocol-settings {
    margin-left: $alignmentMargin;
    .new-btn,
    .alert,
    .warning {
      margin-bottom: 10px;
    }
    .alert,
    .warning {
      margin-right: 20px;
    }
  }

  .flex-wrapper {
    height: 100%;
    overflow: auto;
    min-height: 150px;
    flex-grow: 1;

    .flex-column-container {
      min-width: 540px;
    }
  }
}

.step-item {
  display: flex;
  align-items: center;
  padding: 5px 18px 5px 8px;
  margin-bottom: 3px;
  border: 1px solid $protocolBorderColor;
  border-radius: 3px;
  background-color: $tableRowDetailsBackgroundColor;
  cursor: pointer;

  .step-description {
    margin-right: 5px;

    .step-options {
      width: 100%;
      font-weight: 400;
    }
  }
}

.active {
  &.step-item {
    border: 1px solid $activeStepBoxBorderColor;
    cursor: default;
  }
}

#step-end {
  display: block;
  background-color: $protocolEndBarColor;
  border: none;
  cursor: default;
  text-align: center;
  font-size: 14px;
  color: $white;
  font-weight: normal;
  margin-bottom: 0px;
}

.col-step-num {
  margin-right: 12px;
  width: 16px;
  align-self: start;
  text-align: left;
}

.col-step-action {
  margin-right: 18px;
}

.edit-icon {
  cursor: pointer;
}

%reaction-vol-group {
  display: flex;
  flex-direction: column;
  .label-container {
    flex: 1 0;
  }
  width: 100%;
}

.reaction-vol-group{
  @extend %reaction-vol-group;
}

#reaction-volume-group {
  width: 63px;
  height: 30px;
}

.lid-temp-group {
  @extend %reaction-vol-group;
}

.reaction-vol-panel {
  display: flex;
  flex-direction: column;
  padding-left: $alignmentMargin;
  padding-right: 26px;
  margin-right: 26px;
  height: 100%;
  width: 231px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid $tableRowDetailsTitleColor;

  input {
    padding: 8px;

    &:disabled {
      color: $disabledColor;
      border-color: $disabledColor;
      box-shadow: none;
    }
  }
}

.heading-title {
  color: $newDarkGrey;
}

.reaction-vol-panel-item {
  margin: 0px 0px 15px 0px;
  .heading-title {
    font-weight: normal;
    margin-bottom: 1px;
    text-transform: capitalize;
  }
}

.protocol-newer-version-icon {
  margin-left: 7px;
  height: 30px;
}
