@import '../../../../frontend-common-libs/src/scss/definitions';

.run-report-page {
  flex: 1 0 1px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  .run-report-content {
    display: flex;
    flex: 1 0 1px;
    flex-direction: column;
    overflow: auto;
    margin: 10px 10px 10px 20px;
  }

  .download-pdf-button {
    display: flex;

    .download-pdf-img {
      margin-right: 6px;
      width: 15px;
      height: 20px;
    }

    .download-pdf-txt {
      align-self: end;
      white-space: nowrap;
    }
  }
}

.buttons-panel {
  padding: 0px 20px 0px 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: $mediumShadow;
  background-color: $toolbarBackgroundColor;

  .ptc-run-name {
    font-family: $rubikFontFamily;
    color: $newDarkGrey;
    font-weight: 400;
    font-size: $secondaryFontSize;
    margin-right: 16px;
    @include ellipsis-text;
  }
}