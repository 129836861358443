@import '../../../../../frontend-common-libs/src/scss/definitions';

.instrumentControlTable {
  @mixin checkbox-col {
    width: 40px;
  }

  @mixin status-col {
    width: 130px;
  }

  @mixin protocol-col {
    width: 338px;
    margin-right: 24px;

    @media only screen and (max-width: $minSupportedWidth) {
      width: 130px;
    }
  }

  @mixin lidStatusColumn {
    width: 67px;
  }

  @mixin cycleColumn {
    width: 75px;
  }

  @mixin stepColumn {
    width: 75px;
  }

  @mixin sampleColumn {
    width: 75px;
  }

  th,
  td:nth-of-type(1) {
    @include checkbox-col;
  }

  th,
  td:nth-of-type(4) {
    @include status-col;
  }

  th,
  td:nth-of-type(5) {
    @include protocol-col;
  }

  th,
  td:nth-of-type(6) {
    @include lidStatusColumn;
  }

  th,
  td:nth-of-type(7) {
    @include cycleColumn;
  }

  th,
  td:nth-of-type(8) {
    @include stepColumn;
  }

  th,
  td:nth-of-type(9) {
    @include sampleColumn;
  }
}
