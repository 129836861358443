/* CSS relating to the file list's file items popup menu*/
@import '../src/frontend-common-libs/src/scss/definitions';

#popup-menu-wrapper {
  .visible {
    visibility: visible;
  }
}

.popup-menu-icon{
  width: 16px;
  height: 16px;
  object-fit: contain;
}

%popup-menu-base{
  visibility: visible;
  background: $secondaryColor;
  border-radius: 3px;
  box-shadow: 0 0 6px 2px $shadowColor;
  width: 165px;
  outline: none;
  list-style-type: none;
  padding: 0px;
  z-index: 1000;

  li {
    &:hover {
      cursor:pointer;
    }
  }
}

.popup-menu-absolute{
  @extend %popup-menu-base;
  position: absolute;
  right: 20px;
}

.popup-menu-fixed {
  @extend %popup-menu-base;
  position: fixed;
  right: 60px;
}

.popup-menu-text-red{
  color: red;
}

.popup-menu-item{
  width: auto;
  font-size: 14px;
  outline: 0px;
  height: 23px;
  padding: 18px 10px 18px 14px;
  display:flex;
  align-items: center;

  span {
    padding: 0px 0px 0px 14px;
  }
  &:hover span {
    color: $lightblue;
  }
  &:focus span {
    color: $lightblue;
  }

  &:focus {
    outline: none;
  }
}

.popup-menu-item-disabled {
  color: $disabledColor !important;
  cursor: not-allowed;
  border: none;

  &[disabled] {
    font-size: 14px;
  }

  &:hover {
    span {
      color: $disabledColor;
    }

    svg {
      @include disabledColorFilter;
    }
  }

  &:focus {
    span {
      color: $disabledColor;
    }

    svg {
      @include disabledColorFilter;
    }
  }
}

.popup-menu-sep {
  height: 1px;
  background: $separatorColor;
  margin: 0px 14px;
}

.popup-menu-button {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: 1px solid $focusedElementBorderColor;
  }

  span {
    display: block;
  }
}

.popup-menu-button-with-outline {
  outline: 1px solid $focusedElementBorderColor;
  visibility: visible;
}

.popup-menu-button-img {

  width: 17px;
  height: 17px;
  background-image: url(../img/icons-table-options.svg);

  &:hover {
    background-image: url(../img/icons-table-options-selected.svg);
  }
  &:focus {
    background-image: url(../img/icons-table-options-selected.svg);
  }
}

%popup-menu-item-icon {
  height: 16px;
  width: 16px;
}

.popup-menu-rename-item {

  svg {
    @extend %popup-menu-item-icon;
    background-image: url(../img/icons-options-menu-edit-name.svg);
  }

  &:focus {
    svg {
      background-image: url(../img/icons-options-menu-edit-name-hover.svg);
    }
  }

  &:hover {
    svg {
      background-image: url(../img/icons-options-menu-edit-name-hover.svg);
    }
  }
}

.popup-menu-download-item {

  svg {
    @extend %popup-menu-item-icon;
    background-image: url(../img/icons-options-menu-download.svg);
  }

  &:focus {
    svg {
      background-image: url(../img/icons-options-menu-download-hover.svg);
    }
  }

  &:hover {
    svg {
      background-image: url(../img/icons-options-menu-download-hover.svg);
    }
  }
}

.popup-menu-delete-item {
  svg {
    @extend %popup-menu-item-icon;
    background-image: url(../src/frontend-common-libs/src/img/icons-options-menu-delete.svg);
  }
  &:focus {
    svg {
      background-image: url(../img/icons-options-menu-delete-hover.svg);
    }
  }

  &:hover {
    svg {
      background-image: url(../img/icons-options-menu-delete-hover.svg);
    }
  }
}

.popup-menu-archive-item {
  svg {
    @extend %popup-menu-item-icon;
    background-image: url(../img/icons-options-menu-archive.svg);
  }
  &:focus {
    svg {
      background-image: url(../img/icons-options-menu-archive-hover.svg);
    }
  }

  &:hover {
    svg {
      background-image: url(../img/icons-options-menu-archive-hover.svg);
    }
  }
}

.popup-menu-restore-item {
  svg {
    @extend %popup-menu-item-icon;
    background-image: url(../img/icons-options-menu-restore.svg);
  }
  &:focus {
    svg {
      background-image: url(../img/icons-options-menu-restore-hover.svg);
    }
  }

  &:hover {
    svg {
      background-image: url(../img/icons-options-menu-restore-hover.svg);
    }
  }
}

.popup-menu-move-item {
  svg {
    @extend %popup-menu-item-icon;
    background-image: url(../img/icons-options-menu-move.svg);
  }

  &:focus {
    svg {
      background-image: url(../img/icons-options-menu-move-hover.svg);
    }
  }

  &:hover {
    svg {
      background-image: url(../img/icons-options-menu-move-hover.svg);
    }
  }
}
