@import '../src/frontend-common-libs/src/scss/definitions';

.success-txt {
  color: $primaryColor;
  font-weight: 600;
  margin-top: 10px;
}

.pwd-fb-right,
.pwd-fb-left {
  display: inline-block;
  float: none;
  margin-top: 10px;
  font-size: 12px;
}

.pwd-fb-txt {
  margin-left: 3px;
  line-height: 1;
}

ul.pwd-fb-list > li:not(:last-child) {
  margin-bottom: 7px;
}

#register-user {
  .terms-of-use {
    margin-left: 4px;
    color: $primaryLinkColor;
  }

}

#forgot-pwd-btn {
  width: 100%;
  height: 31px;
  font-size: 14px;
  margin: 24px 0 16px 0;
}

#forgot-pwd {
  color: $primaryLinkColor;
  font-weight: 600;
}

#need-help-box {
  justify-content: center;
}

#forgot-pwd-help-btn {
  color: $primaryLinkColor;
  font-weight: 600;
}