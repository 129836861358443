/* Definitions that are common across the page design */

/* Fonts */
$rubikFontFamily: 'Rubik', sans-serif;

/* Font sizes */
$primaryFontSize: 12px;
$secondaryFontSize: 14px;

/* Colors */
$brGreen: #00a650; /* bio-rad green */
$white: #ffffff;
$leftBarBackground: $white; /* white */
$primaryColor: #009141; /* green */
$primaryHighlightColor: #007b37; /* darker green */
$newDarkGreen: #008741;
$secondaryColor: $white; /* white */
$secondaryColorHighlight: rgba(0, 145, 65, 0.15); /* semi-transparent green */
$secondaryColorText: #009141; /* green */
$darkGrey: #9b9b9b;
$mediumGrey: #dfdfdf;
$lightGrey: #f5f5f5;
$lightblue: #0064b0;
$secondaryDarkBlue: #003156;
$secondaryBlueColor: #2155a3;
$titleTextColor: $darkGrey; /* grey */
$separatorColor: #d2d4d6;
$headerGreyColor: #6d7687; /*dark grey */
$primaryLinkColor: #d44300; /* orange */
$disabledLinkColor: #ffae88; /* light orange */
$shadowColor: #c3c8cc; /* gray */
$invalidColor: #ff3d55; /* red - for invalid state input borders */
$disabledColor: #c5c5c5;
$disabledButtonColor: rgba($primaryColor, 0.65);
$focussedColor: #40b6f3; /* blue - for indicating focussed state */
$tableHeaderText: $darkGrey;
$tableHeaderBorder: $mediumGrey;
$tableRowBorder: $lightGrey;
$toolbarBackgroundColor: #f6f7f9;
$borderGrayColor: #d2d4d6;
$tableRowBackgroundColor: #def1ff;
$focusedElementBorderColor: rgba(255, 102, 0, 0.7); /* orange with opacity */
$newShadowColor: #c8cdd1;
$secondaryGreyColor: #707070;
$newMediumGrey: #dcdfe0;
$newDarkGrey: #3d3d3d;
$headerDarkColor: #272727;
$homeSidebarYellow: #ffde73;
$breadcrumbTextGrayColor: #656d7d;
$primaryBorderColor: #2e7031;
$boxShadowColor: rgba(0, 0, 0, 0.3); /* gray with opacity */
$inputBorderColor: #c2c2c2;
$newBorderGrayColor: #e2e2e2;
$frameBorderGrayColor: #cccccc;
$grayBackground: #fafafa;
$tableRowDetailsBackgroundColor: #f7f7f7;
$tableRowDetailsTitleColor: #6a6a6a;
$plateBorderColor: #c2c2c2;
$secondaryBorderGrayColor: #94a2b0;
$secondaryBackgroundColor: #dde3e7;
$primaryHoverLinkColor: #872b00;
$protocolGraphPrimaryColor: #ff6600;
$protocolEndBarColor: #585858;
$protocolStepBoxActiveBackground: #dbf6e4;
$protocolBorderColor: #adadad;
$activeStepBoxBorderColor: #595959;
$tableFocusBackgroundColor: #307ab3;
$plateHeaderBackgroundColor: #e5e5e5;
$wellDefaultBackgroundColor: #b3deff;
$wellHiddenBackgroundColor: #d5dce0;
$wellDefaultHoverColor: #9b9b9b;
$wellActiveBackgroundColor: #2873b5;
$excludedWellSlashColor: #575757;
$fontWellColor: #575757;
$lightGray: #a2aabb;
$lightGrayText: #858585;
$errorRed: #a94442;
$darkGray: #acacac;
$rowHover: #f1f1f1;
$placeholderTextColor: #a9a9a9;

/* Filters */
@mixin disabledColorFilter {
  filter: invert(98%) sepia(0%) saturate(0%) hue-rotate(179deg) brightness(84%)
    contrast(84%); // => #c5c5c5
}

@mixin hoverColorFilter {
  filter: invert(7%) sepia(68%) saturate(4550%) hue-rotate(193deg)
    brightness(111%) contrast(101%); // => #003156
}

@mixin defaultColorFilter {
  filter: invert(24%) sepia(92%) saturate(1793%) hue-rotate(188deg)
    brightness(92%) contrast(101%); // => #0064b0
}

@mixin convertToColor($color) {
  filter: drop-shadow(0px 1000px 0 $color);
  transform: translateY(-1000px);
}

@mixin convertToDisabledColor {
  @include convertToColor($disabledColor)
}


/* Sizes */
$minSupportedWidth: 1366px;
$tabletLandscapeMaxWidth: 1024px;
$tabletPortraitMaxWidth: 768px;
$mobilePortraitMaxWidth: 480px;
$mobileLargerPortraitMaxWidth: 992px;

/* box shadow */
$primaryBoxShadow: 0 0 14px 2px $newShadowColor;
$mediumShadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
$insetShadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset,
  0 1px 1px rgba(0, 0, 0, 0.075) inset;
$glowShadow: 0 0 8px $focusedElementBorderColor;

/*text-shadow*/
$sidebarTextShadow: 0 1px 0 rgba(0, 0, 0, 0.5);

@mixin ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%focused-element-outline {
  outline: 1px solid $focusedElementBorderColor;
}

%focus-outline {
  &:focus {
    @extend %focused-element-outline;
  }
}

%no-focus-outline-on-click {
  &:focus {
    &:not(:active) {
      @extend %focused-element-outline;
    }
  }
}

%no-border-button {
  background-color: inherit;
  border: none;
  padding: 0;
  &:focus {
    outline: none;
  }
}

